export default props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 259.14 259.14"
		fill="currentColor"
		{...props}
	>
		<path d="M129.57,241.13A111.56,111.56,0,1,0,18,129.57,111.56,111.56,0,0,0,129.57,241.13Zm0,18A129.57,129.57,0,1,1,259.14,129.57,129.57,129.57,0,0,1,129.57,259.14Z" />
		<path d="M39.43,157.94c1.06-3,2.17-6,3.18-9.05,2.24-6.71,4.41-13.45,6.64-20.17,1.19-3.61,3.91-4.24,6.45-1.44q10.21,11.29,20.36,22.65c2.39,2.67,1.39,5.43-2.17,6.18s-7.21,1.51-11.15,2.33c1.35,2.82,2.48,5.57,3.94,8.14,11.38,20,28.4,32.36,51.08,36.3,18.5,3.21,35.8-.59,51.47-11A70.17,70.17,0,0,0,199.06,150a10.92,10.92,0,0,1,.42-1.34,3.32,3.32,0,0,1,3.9-2.2,3.26,3.26,0,0,1,2.42,4.07,80.85,80.85,0,0,1-3,9.71c-10.86,26.49-30.3,43-58.29,48.9-34.4,7.26-70.79-11.25-85.64-43.14a48,48,0,0,1-2.08-4.84c-.4-1.18-1-1.34-2.1-1.1-3.32.73-6.68,1.29-10,2.15-2.29.6-4,.08-5.29-1.84Z" />
		<path d="M108,184.17a10.17,10.17,0,0,1,.17-1.75q5.16-20.23,10.34-40.46c.14-.53.24-1.06.4-1.79H106.42c-2.65,0-2.68,0-1.87-2.53Q112,114.83,119.43,92a2.21,2.21,0,0,1,2.51-1.83c10.41.06,20.81,0,31.21,0,.64,0,1.28.14,2.33.26-7.25,13.87-14.34,27.44-21.64,41.39H153.2l.24.56-44.77,52Z" />
		<path d="M196.71,104.45c-2.28-4.25-4.14-8.36-6.57-12.11-11.43-17.67-27.57-28.57-48.27-32.23C123.23,56.81,105.82,60.57,90,71a70.1,70.1,0,0,0-29.86,41.82c-.41,1.58-1,3.07-2.8,3.46-2.58.55-4.51-1.56-3.86-4.29a71.18,71.18,0,0,1,6.69-17.7C71.13,74.16,87.68,60.9,109.83,54.92c37.48-10.13,77.52,9.84,92.22,45.81,1,2.4,1,2.41,3.43,1.91,3.16-.64,6.31-1.3,9.46-2,3.56-.75,5.6,1.5,4.45,5q-4.74,14.43-9.52,28.85c-1.08,3.25-3.91,3.93-6.2,1.4q-10.46-11.55-20.79-23.22c-2-2.31-1-5.11,2.18-5.8C188.71,106.08,192.38,105.35,196.71,104.45Z" />
	</svg>
)
