export default props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 259.14 259.14"
		fill="currentColor"
		{...props}
	>
		<path d="M129.57,241.13A111.56,111.56,0,1,0,18,129.57,111.56,111.56,0,0,0,129.57,241.13Zm0,18A129.57,129.57,0,1,1,259.14,129.57,129.57,129.57,0,0,1,129.57,259.14Z" />
		<path d="M173.83,181.26a3.15,3.15,0,0,1,3.3-3h0a3.21,3.21,0,0,1,3.16,2.29c3.49-4.57,5.76-11.45,7.38-21.87,4.3-27.63,1.41-55.22,1.16-57.48-.4-10.24-4.64-16.46-8.71-20.13A3.26,3.26,0,0,1,177,83.17h0a3.16,3.16,0,0,1-3.31-3V76.85a22.28,22.28,0,0,0-3.19-1.17c-12.28-4.66-36.39-4.88-39.14-4.89s-26.81.28-39.11,5A22.27,22.27,0,0,0,87,78c-.24.14-.52.34-.78.5V80.3a3,3,0,0,1-3,3h0a2.93,2.93,0,0,1-1.93-.76c-3.6,3.8-7,9.71-7.27,18.71-.14,1.18-3.19,29.37,1.27,57.59C76.74,168,78.7,174.5,81.55,179a2.94,2.94,0,0,1,1.78-.65h0a3,3,0,0,1,3,3v2.93a19.51,19.51,0,0,0,5.7,2.9l.5.17.15.05a50.14,50.14,0,0,0,7.71,1.61,170.26,170.26,0,0,0,30.57,2.7h.74A170.25,170.25,0,0,0,162.57,189a52.53,52.53,0,0,0,7.82-1.65l.11,0h0l.47-.16a20,20,0,0,0,3-1.25,8.82,8.82,0,0,1-.17-1.47Zm-4.26,3.31h0Zm-.4-2.88-.41.14-.06,0a45.94,45.94,0,0,1-7.08,1.48,165,165,0,0,1-30,2.67h-.17a159.93,159.93,0,0,1-30.19-2.62,45,45,0,0,1-7-1.45l0,0-.46-.15c-5.06-1.62-9.85-4.66-12.87-23.83-4.33-27.44-1.27-55.79-1.22-56.29C80.08,90.8,85.41,85.5,89.82,83a16.57,16.57,0,0,1,3.93-1.69,2.77,2.77,0,0,0,.41-.12c9.17-3.57,27.72-4.6,37.22-4.65s28.09,1,37.25,4.57a2.91,2.91,0,0,0,.39.12A16.49,16.49,0,0,1,173,82.88c4.42,2.53,9.76,7.81,10.15,18.65,0,.08,0,.15,0,.22,0,.29,3.16,28.63-1.11,56.07C179.05,177,174.27,180.05,169.17,181.69Z" />
		<path d="M174.6,104.22c-.31-6.92-5.22-12.27-5.49-12.55-8.54-8.63-29.19-9.91-37.77-10.06-8.47.17-29.11,1.48-37.7,10.2-.21.22-5.16,5.64-5.39,12.4a154.07,154.07,0,0,0,1.54,41.09c.55,3.9,3.6,23.64,9.83,28.62.2.16,4.48,3.61,13.58,4.9a6.59,6.59,0,0,0,1.24,3.44,4.67,4.67,0,0,0,3.41,1.79c.37,0,9.11.53,13.73.64,4.48-.12,13.21-.65,13.63-.67a4.66,4.66,0,0,0,3.36-1.79,6.6,6.6,0,0,0,1.23-3.44c9.1-1.32,13.37-4.78,13.54-4.91,6.25-5,9.26-24.77,9.78-28.57A155.34,155.34,0,0,0,174.6,104.22Zm-6.77,40.26c-1.22,8.84-4.46,22.51-7.86,25.23,0,0-4.14,3.22-13.15,4a2.7,2.7,0,0,0-1.87,1,2.65,2.65,0,0,0-.53,2.07,6,6,0,0,1,0,1.85c-1.84.11-9.13.53-12.85.64-3.86-.1-11.25-.51-13-.61a5.9,5.9,0,0,1,0-1.84,2.67,2.67,0,0,0-2.4-3.11c-9-.79-13.12-4-13.17-4-3.39-2.71-6.67-16.38-7.92-25.32a152.77,152.77,0,0,1-1.5-39.83c.18-4.94,3.93-9.12,3.93-9.12,5.84-5.92,21.36-8.29,33.84-8.54,12.58.22,28.11,2.57,33.89,8.41,0,0,3.83,4.21,4,9.17a1.49,1.49,0,0,0,0,.21A153,153,0,0,1,167.83,144.48Z" />
		<path d="M158.88,95.23h0a2.67,2.67,0,0,0-2.67,2.68v2.18a101.79,101.79,0,0,0-24.73-3.59h-.07c-9.53,0-19.62,2.31-24.79,3.67V98a2.69,2.69,0,0,0-2.68-2.68h0A2.68,2.68,0,0,0,101.26,98v5.69a2.67,2.67,0,0,0,2.68,2.68,2.82,2.82,0,0,0,.8-.12c.14,0,14.2-4.4,26.67-4.41h.05c12.94,0,26.46,4.3,26.6,4.34a2.67,2.67,0,0,0,2.4-.38,2.7,2.7,0,0,0,1.1-2.17V97.9A2.67,2.67,0,0,0,158.88,95.23Z" />
		<path d="M198.52,67.13A12.85,12.85,0,0,0,190,64h-4.2c-6.68,0-12.11,4.82-12.1,10.72v2.11c.65.3,1.35.64,2.11,1.07a25.16,25.16,0,0,1,4.31,3.17,2.74,2.74,0,0,0,.2-.89V74.73c0-2.62,2.46-4.76,5.5-4.76H190a5.86,5.86,0,0,1,3.9,1.42,4.41,4.41,0,0,1,1.6,3.33l.13,109.65a4.41,4.41,0,0,1-1.58,3.34,5.91,5.91,0,0,1-3.91,1.42H186c-3,0-5.5-2.13-5.5-4.75v-3.14a2.67,2.67,0,0,0-.15-.68,17.88,17.88,0,0,1-6.29,5.29c.82,5.2,5.84,9.23,12,9.23h4.18a12.87,12.87,0,0,0,8.53-3.12,10.06,10.06,0,0,0,3.58-7.59l-.14-109.66A10.05,10.05,0,0,0,198.52,67.13Z" />
		<path d="M177,83.17h0a3.26,3.26,0,0,0,3.11-2.08,25.16,25.16,0,0,0-4.31-3.17c-.76-.43-1.46-.77-2.11-1.07V80.2A3.16,3.16,0,0,0,177,83.17Z" />
		<path d="M180.29,180.57a3.21,3.21,0,0,0-3.16-2.29h0a3.15,3.15,0,0,0-3.3,3v3.13a8.82,8.82,0,0,0,.17,1.47A17.88,17.88,0,0,0,180.29,180.57Z" />
		<path d="M81.55,179a2.93,2.93,0,0,0-1.19,2.33v3.13a4.86,4.86,0,0,1-4.94,4.77H71.66a5,5,0,0,1-3.51-1.42,4.61,4.61,0,0,1-1.44-3.33L66.59,74.85A4.65,4.65,0,0,1,68,71.52a5,5,0,0,1,3.51-1.43h3.76a4.86,4.86,0,0,1,4.95,4.75v5.47a2.93,2.93,0,0,0,1,2.21,25.16,25.16,0,0,1,4.9-4V74.83a10.82,10.82,0,0,0-10.9-10.69H71.53a10.92,10.92,0,0,0-7.67,3.12,10.53,10.53,0,0,0-3.21,7.59l.12,109.65A10.53,10.53,0,0,0,64,192.1a10.92,10.92,0,0,0,7.65,3.11h3.78a10.82,10.82,0,0,0,10.88-10.71v-.21A18.77,18.77,0,0,1,81.55,179Z" />
		<path d="M83.22,83.28h0a3,3,0,0,0,3-3V78.51a25.16,25.16,0,0,0-4.9,4A2.93,2.93,0,0,0,83.22,83.28Z" />
		<path d="M83.33,178.38h0a2.94,2.94,0,0,0-1.78.65,18.77,18.77,0,0,0,4.76,5.25v-2.93A3,3,0,0,0,83.33,178.38Z" />
	</svg>
)
