import React from 'react'
const ServiceGuarenteeIcon = ({ theme, ...rest }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 422.07 411.12" {...rest}>
		<path
			d="M46.14,238.27a47.18,47.18,0,0,1,4.19,11.17c3.66,14.62-2.28,19.94-17.9,23.85s-23.37,2-27-12.62a46.66,46.66,0,0,1-1.54-11.84L11,247.9a58.51,58.51,0,0,0,1.51,9.26c2.65,10.58,9,9.18,17.68,7s14.94-3.89,12.28-14.51a57.26,57.26,0,0,0-3-8.86Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M30.07,199.91c12.48-.31,15.92,6.32,16.17,16.62S43.4,233.64,30.92,234L.85,234.7l-.23-9.09,30.58-.76c5.39-.14,7.46-2.25,7.32-8.13s-2.32-7.87-7.71-7.74l-30.58.76L0,200.65Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M14.86,178.59c4.47.89,5.69-2,8.09-6.88,4-8.05,8-11.06,16.09-9.44,9.82,2,12.15,8.65,10,19.18A61,61,0,0,1,45.13,194l-7-2.31a92.75,92.75,0,0,0,3.33-11.45c.94-4.67.46-7.75-4-8.64-4.15-.83-5.74,2.24-7.82,6.12-4.05,7.49-7.45,11.69-15.85,10-9.64-1.94-11.59-8.25-9.54-18.48a65.09,65.09,0,0,1,3.94-12.45L15,159a65.67,65.67,0,0,0-2.94,9.6C11.35,172.22,10.42,177.7,14.86,178.59Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M30.11,118.06l-4.34,10.27,34.94,14.76-3.6,8.52L22.17,136.85l-4.32,10.23L11,144.18l12.26-29Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M47.57,117.34C34.15,107.85,31,100.69,39.13,89.16S55.08,78.23,68.5,87.72s16.58,16.65,8.44,28.16S61,126.82,47.57,117.34Zm15.54-22C55.56,90,50.28,86.52,45.32,93.53s.09,10.84,7.64,16.18,12.82,8.82,17.78,1.79S70.66,100.69,63.11,95.35Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M96.61,68.8,89.4,45.65c-1.23-4-.57-7.09,1.49-8.76,3.15-2.57,5.51-2.1,8.76,1.55l26,29.23-6.89,5.6L97.41,48.74l0,0,7.73,22.4c1,2.84.6,5-1.25,6.46s-4,1.47-6.62-.06L76.74,65.66l0,0L96.33,91.54l-6.64,5.39L66.41,65.42c-2.76-3.73-2.81-6.35.29-8.87,2-1.65,5-1.81,8.7.31Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M141.38,19.29,130,24.46c-4.95,2.24-5.34,3.65-3.27,8.21l2.12,4.68,16.42-7.44,3,6.67L131.85,44l2.43,5.36c2.07,4.57,3.39,5.23,8.34,3L154,47.21,157,53.7c-2.85,1.46-8.32,4.15-11.08,5.4-7.47,3.38-14.54,6.09-18.92-3.59l-9.69-21.37c-4.38-9.68,2.32-13.19,9.79-16.57,3-1.36,8.44-3.64,11.36-4.79Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M172.48,49.74l-9,1.89L154.33,8a87.09,87.09,0,0,1,14.18-4.25c9.82-2.06,18.25-1.8,20.77,10.2,1.64,7.85-.88,12.24-5.76,14.92l13.87,15.65L187,46.69,174.4,31.85c-1.82.38-3.89.72-5.48.95Zm-5.06-24.11a47.76,47.76,0,0,0,5.54-.92c5.58-1.17,8.58-3.29,7.39-8.93-1-4.92-3.65-6.42-10.13-5.06a43.93,43.93,0,0,0-5.6,1.58Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M228.64,11.62c-.47,4.53,2.56,5.48,7.61,7.41,8.39,3.25,11.76,7,10.9,15.14-1,9.95-7.47,12.9-18.16,11.79A61.24,61.24,0,0,1,216.1,43.2l1.65-7.13a94.64,94.64,0,0,0,11.71,2.24c4.74.5,7.76-.26,8.23-4.8.44-4.2-2.77-5.49-6.82-7.21C223,23,218.53,20,219.42,11.46c1-9.78,7.12-12.31,17.5-11.23A64.71,64.71,0,0,1,249.69,3l-1.57,6.94a65.83,65.83,0,0,0-9.83-2C234.65,7.53,229.11,7.11,228.64,11.62Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M289.35,20.8,277.42,17c-5.18-1.65-6.41-.86-7.93,3.91l-1.56,4.89,17.18,5.48-2.23,7-17.17-5.47-1.79,5.6c-1.52,4.79-1,6.16,4.19,7.81L280,50l-2.16,6.79c-3.09-.82-9-2.49-11.85-3.41-7.81-2.49-14.87-5.21-11.65-15.34l7.13-22.36c3.23-10.12,10.56-8.24,18.36-5.75,3.14,1,8.71,3,11.65,4.06Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M292,64.24l-7.93-4.63,22.46-38.52a86.93,86.93,0,0,1,13.37,6.36c8.67,5.05,14.75,10.9,8.57,21.49-4.05,6.93-8.86,8.5-14.28,7.21L314,77.06l-9.15-5.34.58-19.47c-1.6-.93-3.37-2.07-4.7-3ZM304.43,43a48.67,48.67,0,0,0,4.73,3c4.93,2.87,8.57,3.31,11.48-1.67,2.53-4.34,1.59-7.21-4.13-10.54a44.34,44.34,0,0,0-5.21-2.59Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M338,91.75c-4,2.2-6.9,1.91-9.29-.37s-2.9-5-.86-9.27l17.91-37.74,7.12,6.76L336.24,83.59l31.65-18.22,6.86,6.52Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M382.1,82.23l5.48,7.43-36.49,27-5.49-7.43Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M370.28,148.74a47.13,47.13,0,0,1-6.68-9.89c-7-13.37-2.41-19.92,11.88-27.35s22.26-7.36,29.2,6a47,47,0,0,1,4.24,11.16l-6.68,2.56a57.41,57.41,0,0,0-3.62-8.65c-5-9.68-10.9-6.84-18.82-2.73s-13.62,7.26-8.58,17a57.91,57.91,0,0,0,5,7.91Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M415.19,170.17l-3.42-12c-1.49-5.23-2.83-5.82-7.65-4.45l-4.94,1.41,4.94,17.34-7,2-4.94-17.35-5.66,1.61c-4.83,1.38-5.68,2.59-4.19,7.82l3.43,12-6.85,1.95c-1-3-2.88-8.84-3.72-11.76-2.24-7.88-3.88-15.27,6.35-18.18l22.56-6.42c10.23-2.92,12.71,4.23,14.95,12.12.9,3.16,2.35,8.88,3.06,11.94Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M102.64,344.61,114.94,355l-11,13c-5.4,6.38-10.75,2.52-17.57-3.25-11.48-9.71-9.81-17.53.59-29.82,10.52-12.45,17.83-15.24,29.31-5.54a70.81,70.81,0,0,1,10.2,10.4l-5.19,4.91a81.36,81.36,0,0,0-8.66-8.37c-8.32-7-12.73-2.17-18.5,4.66s-9.85,12-1.56,19c3.61,3.05,4.86,2.24,6.49.31l4.63-5.47L98.05,350Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M148.83,384.1c-5.48,11.22-13,11.21-22.21,6.68s-13.87-10.42-8.39-21.63l13.21-27,8.17,4L126.18,373.6c-2.37,4.84-1.45,7.66,3.83,10.24s8.05,1.57,10.42-3.27l13.43-27.49,8.17,4Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M153.33,402.19l21.27-35.92c2.39-4,5.17-5.39,8.59-4.67s5.4,3.17,5.95,7.7l4.92,41.38-9.57-2-1.1-11.7-15.15-3.16-5.61,10.31Zm29.39-12.57-1.79-19.11-9.17,16.83Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M216.47,410.69l-9.18.43-2.08-44.54a86.16,86.16,0,0,1,14.68-1.93c10-.47,18.3,1.13,18.87,13.38.38,8-2.81,12-8.07,13.81l11.2,17.66-10.58.5-10.1-16.66c-1.84.08-4,.09-5.55.06Zm-1.15-24.6a47.74,47.74,0,0,0,5.61,0c5.7-.27,9-1.88,8.73-7.64-.24-5-2.59-6.91-9.2-6.61a48.5,48.5,0,0,0-5.78.66Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M254.53,407.48l.92-41.73c.11-4.69,1.87-7.24,5.2-8.29s6.26.12,9,3.8l24.58,33.65-9.32,2.95-6.69-9.66-14.76,4.68.17,11.73ZM274,382.11l-10.93-15.77.26,19.16Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M323.2,363.67l-18-28,7-4.46,21,32.76c2.61,4.07,2,6.92-1,8.83-2.83,1.82-5.41,1.73-9.81-.81l-27.16-15.71,18,28.11-7,4.47-21-32.78c-2.61-4.07-2-6.93,1-8.81,2.66-1.7,5.51-1.66,9.79.8Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M341.33,311.52l-7.7,8.07,27.44,26.18-6.38,6.69-27.44-26.17-7.66,8-5.39-5.14L336,306.39Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M358.52,284,352,294.63c-2.85,4.62-2.38,6,1.88,8.64l4.38,2.7,9.46-15.35,6.23,3.84-9.46,15.35,5,3.08c4.28,2.64,5.75,2.45,8.6-2.18l6.57-10.65,6.06,3.74c-1.54,2.8-4.57,8.09-6.17,10.67-4.3,7-8.65,13.17-17.7,7.59l-20-12.31c-9-5.58-5.45-12.24-1.15-19.21,1.73-2.8,5-7.73,6.75-10.32Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M370.71,251.28l-4.14,11.81c-1.8,5.13-1,6.38,3.68,8l4.85,1.7,6-17,6.91,2.42-6,17,5.55,1.94c4.74,1.67,6.13,1.16,7.93-4l4.14-11.81,6.73,2.35c-.91,3.07-2.75,8.88-3.75,11.75-2.72,7.73-5.64,14.72-15.67,11.19L364.8,279c-10-3.52-7.94-10.79-5.22-18.53,1.09-3.1,3.21-8.61,4.39-11.52Z"
			fill={theme === 'light' ? '#e4e5e6' : '#414042'}
		/>
		<path
			d="M283.53,152.18c-.63-.27-1.28-.51-1.94-.72a25.62,25.62,0,0,0-14.79-.05h0a31.93,31.93,0,0,0-18,12.23,27.42,27.42,0,0,0-3.73,23.53,31,31,0,0,0,1.27,3.67l.42.95c.4.88.83,1.73,1.3,2.54.15.27.29.54.45.79a27.33,27.33,0,0,0,4.5,5.52l.52.46A26.13,26.13,0,0,0,256,203l.33.24a27.27,27.27,0,0,0,6.44,3.2l.38.12a30,30,0,0,0,3.44.92h0a33.78,33.78,0,0,0,15.44-.7c8.47-2.33,16-8.49,19.41-16.55L287.74,185h0c-.22.56-.45,1.1-.7,1.63a13.89,13.89,0,0,1-9.17,8c-6.3,1.73-12.08-1-15.08-6.84l37.81-10.39h0l-1.4-5.1C296.43,162.24,290.82,155.29,283.53,152.18Zm-23.79,24.54a11.21,11.21,0,0,1,1.58-8.13,12,12,0,0,1,7.19-5,10.13,10.13,0,0,1,12.74,7.24Zm-73.42-9.49a9.82,9.82,0,0,0,6.85-12.06,9.76,9.76,0,1,0-6.85,12.06Zm-13.61,68.24L189,231l-22.58-82.14-16.3,4.48Zm46.15-86.31-16.3,4.48,4.31,15.65-26.73,7.34h0l14.52,52.8L211,225,200,185.19l10.43-2.87,6.9,25.1s0,.08,0,.12a22.07,22.07,0,0,0,.73,2.17c.1.23.21.46.31.68.21.48.42.93.66,1.37.12.22.25.44.38.66.26.41.52.81.81,1.18l.37.51a15.11,15.11,0,0,0,1.21,1.3l.11.12a12.78,12.78,0,0,0,1.55,1.22c.08.06.16.1.24.15a13.45,13.45,0,0,0,1.43.81l.4.18a15,15,0,0,0,1.45.56l.39.12a15.06,15.06,0,0,0,1.79.43l.09,0h0A27,27,0,0,0,241,218.1a34.86,34.86,0,0,0,11-4.76l0-.05h0l-3.72-13.52h0c-.79,1.27-2.91,2.25-4.65,2.86-.58.21-1.12.37-1.56.49-7.06,1.94-8.85-2-10.4-7.66l-4.84-17.6,14.34-3.94-3.58-13-14.35,3.95Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
		<path
			d="M117.72,175.67c-15.39,4.24-26.27,15.87-28.26,30.45a38.17,38.17,0,0,0,1.12,15.39c0,.18.12.34.17.51.34,1.18.71,2.34,1.14,3.45.15.39.34.75.5,1.13.37.87.74,1.75,1.16,2.57.17.34.37.65.56,1,.46.84.92,1.66,1.44,2.45.16.25.35.48.52.73.57.83,1.16,1.64,1.79,2.41.13.16.28.31.42.47.7.82,1.43,1.61,2.19,2.35l.28.26c.83.79,1.7,1.54,2.6,2.24l.14.1a34,34,0,0,0,3,2.05l0,0c8.73,5.35,19.75,6.84,30.85,3.79,9.48-2.61,17.58-8,23-17.46h0a41,41,0,0,0,2-4l-14.7-5.86a31.81,31.81,0,0,1-2.76,5,22,22,0,0,1-11.82,8.81c-.39.13-.75.28-1.15.39a23.72,23.72,0,0,1-2.42.51A18,18,0,0,1,113.06,229a20.3,20.3,0,0,1-3.86-6.22L161,208.54l-1.29-4.68C153.23,180.22,136.69,170.46,117.72,175.67Zm-12,27.21a16.69,16.69,0,0,1,3.66-8,18.23,18.23,0,0,1,9.42-5.81c8.78-2.42,15.86.26,19.7,6.81a20.45,20.45,0,0,1,2,4.57L105.7,210A23.47,23.47,0,0,1,105.74,202.88Z"
			fill="#59c8df"
		/>
		<path
			d="M217.89,150.86l-13.62,3.75,4.3,15.65-26.72,7.34,5.77,21c4.55-1.4,9-2.84,13.46-4.31l-2.77-10.06,13.11-3.6,2.54,9.23q6.79-2.41,13.3-4.91l-2.21-8.06,14.34-3.95-2.85-10.36-14.35,3.94Zm49.28,1.88c-12.87,3.54-21,13.3-21.71,24.81q6.75-2.9,13-5.83c.94-4.07,3.93-7.93,9.63-9.5a11.78,11.78,0,0,1,8.12.66q6.14-3.28,11.64-6.56C282.33,151.91,275.17,150.54,267.17,152.74ZM118.09,177c-20.36,5.59-31.31,23.74-26.35,43.33a604.88,604.88,0,0,0,67.33-13.65l-.67-2.46C152.36,182.26,136.91,171.83,118.09,177ZM104.54,211.8l-.22-1.56c-1.56-11.23,3.72-19.64,14.13-22.5,11.33-3.11,20.3,1.61,23.41,12.34l.39,1.35ZM186,165.89A8.42,8.42,0,1,0,175.61,160,8.43,8.43,0,0,0,186,165.89Zm-20.2,39,2.37-.63c3.79-1,7.52-2.11,11.22-3.2l-13.88-50.51-13.62,3.74Z"
			fill="#fff"
			opacity="0.3"
		/>
		<polygon
			points="304.67 122.25 313.28 131.94 326.14 130.34 319.59 141.51 325.09 153.24 312.43 150.47 302.97 159.32 301.7 146.43 290.36 140.17 302.23 134.97 304.67 122.25"
			fill="#59c8df"
		/>
		<path
			d="M318.88,132.64l-6.15.76-7.23-8.13L303.45,136l-10,4.36,9.53,5.26.08.81A102.66,102.66,0,0,0,318.88,132.64Z"
			fill="#fff"
			opacity="0.32"
		/>
		<path
			d="M136.9,278.75c1,3.82.88,7.12-3.89,8.43l-12.77,3.51c-2.14.59-3.88.11-4.67-2.74l-4.8-17.47c-.78-2.86.47-4.16,2.61-4.75l12.76-3.51c3.6-1,6,.88,7.27,5.37.74,2.67.54,5.18-2.06,6.32l0,.14C134.38,273.5,136,275.5,136.9,278.75Zm-19.57-2.83,9.48-2.61c2.11-.57,2.5-1.68,1.81-4.17-.59-2.14-1.55-3.14-3.69-2.55l-7.67,2.1c-1.43.4-1.68.85-1.29,2.28Zm2.46,8.95c.39,1.43.85,1.69,2.27,1.3l7.46-2.05c2.46-.68,3.13-1.86,2.44-4.36s-1.9-3.31-4-2.72l-9.56,2.63Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
		<path
			d="M160.51,276.17c.58,2.14-.19,3.51-2.33,4.09l-6.92,1.91c-4.28,1.17-7.75.55-9.12-4.44l-3.42-12.44,4.17-1.15L146,275.48c.78,2.85,2.3,4,5.15,3.26l4.1-1.13a1,1,0,0,0,.78-1.36l-4-14.62,4.17-1.15Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
		<path
			d="M160.5,256.61l-1.06-3.85,4.17-1.14,1.06,3.85Zm.68,2.5,4.18-1.15,5.19,18.9L166.38,278Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
		<path
			d="M168.68,250.53l4.17-1.15,6.87,25-4.18,1.15Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
		<path
			d="M200.8,265.09q.88,3.21-2.33,4.1L191.88,271c-6.07,1.67-8.78-1.65-10.35-7.36s-.91-9.87,5.15-11.54l5.63-1.55-1.66-6.06,4.17-1.15ZM188.6,255c-4,1.11-3.74,3.87-2.76,7.44s2.16,6.15,6.19,5.05l3.53-1a1,1,0,0,0,.77-1.36l-3.13-11.41Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
		<path
			d="M213.23,265.13c-6.06,1.66-8.07-1.47-9.74-7.53-2.06-7.49.07-10.37,6.48-12.14,5.35-1.47,9.49-1.07,11.16,5,.58,2.14.19,3.78-1.95,4.37l-11,3c.79,2.86,1.83,4.72,5.4,3.73l9.23-2.53.89,3.2ZM216,252.25c.82-.22.77-.82.63-1.36-.69-2.5-3.26-2.9-5.75-2.22-3.57,1-4.42,2.75-3.54,6Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
		<path
			d="M225.51,249.09c-1.39-5.06.61-7.83,5.67-9.22l2.82-.78.88,3.21-1.75.48c-3.35.92-4.07,2.92-3.15,6.27l2.94,10.66-4.18,1.15Z"
			fill={theme === 'light' ? '#dcddde' : '#353e47'}
		/>
	</svg>
)
export default ServiceGuarenteeIcon
