export default props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 259.14 259.14"
		fill="currentColor"
		{...props}
	>
		<path d="M129.57,241.13A111.56,111.56,0,1,0,18,129.57,111.56,111.56,0,0,0,129.57,241.13Zm0,18A129.57,129.57,0,1,1,259.14,129.57,129.57,129.57,0,0,1,129.57,259.14Z" />
		<path d="M130.51,95.42c-5.39,0-7.26,3.09-8.86,7.24L103.1,149.31c-.45,1.17-.89,2.16-1.61,2.17s-1.17-1-1.63-2.15l-19-46.47c-1.73-4.13-3.64-7.17-9-7.15-4.94,0-8,2.56-8,7.15l.28,56.53,8.36,0-.45-53.3c0-.9.44-1.8,1.16-1.8s1.26,1.07,1.63,2l18.93,46.55c1.82,4.4,3.45,7.18,7.76,7.15s5.83-2.81,7.61-7.23L127.69,106c.36-.9,1-2,1.61-2,.36,0,1.17.17,1.17,1.79l.09,53.3,8.36,0-.28-56.54C138.62,97.9,135.46,95.4,130.51,95.42Zm63.48,24c0-7.19-4.63-8.24-12.09-8.2l-11.06,0c-15.73.08-19.72,9.09-19.65,23.92.08,15.28,4.16,23.8,19.89,23.72l15.46-.08c-.68,6.66-5.61,9.38-12,9.41l-19.87.1,0,6.11,19.87-.1c14.92-.07,19.65-7,19.58-20.14Zm-21.5,33.37c-11.42.06-13.33-6-13.39-17.64-.06-11.24,1.8-17.72,13.21-17.78l10.7,0c2.42,0,3.42,1.15,3.43,3.22l.16,32.18Z" />
	</svg>
)
