export default props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 259.14 259.14"
		fill="currentColor"
		{...props}
	>
		<path d="M129.57,241.13A111.56,111.56,0,1,0,18,129.57,111.56,111.56,0,0,0,129.57,241.13Zm0,18A129.57,129.57,0,1,1,259.14,129.57,129.57,129.57,0,0,1,129.57,259.14Z" />
		<path d="M189.05,129.43c3,3.36,4.47,7.13,3.22,11.49s-4.21,7-8.78,8.26c.44.6.74,1,1.07,1.45a11.34,11.34,0,0,1-4.77,17.68c-1,.4-1.54.67-1.22,2a11.39,11.39,0,0,1-11.3,14.2c-13.2.07-26.41-.17-39.61.07-5.85.1-11-2.2-16.47-3.5-1-.24-2-.61-3.06-.91a7.19,7.19,0,0,1-5.55-7.44q.09-23.76.21-47.5c0-1.63,0-3.25,0-4.87A11.54,11.54,0,0,1,106,111.8a200,200,0,0,0,21.4-28.57,57.57,57.57,0,0,0,6.39-16.16c.81-3.14,1.85-6.23,2.55-9.39.89-4.09,3.83-5.91,7.43-6.86a12.18,12.18,0,0,1,5-.07,7.67,7.67,0,0,1,6.73,6.13c1.45,5.8,1.21,11.64.89,17.52-.32,5.71-2.45,10.82-4.81,15.94a99.27,99.27,0,0,0-4.34,11.77c-.86,2.75.32,4.33,3.23,4.54s5.82.1,8.72.11c8.95,0,17.91-.09,26.86.12,9.19.23,14.43,9.86,9.53,17.47-1.09,1.7-3.06,2.84-4.68,4.18A10.09,10.09,0,0,1,189.05,129.43Z" />
		<path d="M95.82,148.06c0,10.85,0,21.7,0,32.55,0,3-1.56,4.56-4.57,4.56q-16.29,0-32.56-.13c-3.24,0-4.72-1.54-4.71-4.79q.08-32.48.21-64.95c0-3,1.58-4.5,4.6-4.5l32.56.07c3.15,0,4.71,1.61,4.71,4.8q0,16.2,0,32.39Z" />
	</svg>
)
