export default props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 259.14 259.14"
		fill="currentColor"
		{...props}
	>
		<path d="M129.58,241.13A111.56,111.56,0,1,0,18,129.57,111.55,111.55,0,0,0,129.58,241.13Zm0,18A129.57,129.57,0,1,1,259.14,129.57,129.58,129.58,0,0,1,129.58,259.14Z" />
		<path d="M115,97.68c-15.53,0-15.94,11-15.94,26.93s.41,26.9,15.94,26.9,16-11,16-26.9S130.56,97.68,115,97.68Z" />
		<path d="M170.83,118.36a54.44,54.44,0,1,0-26.94,56,33.15,33.15,0,1,0,26.94-56ZM115,161.84c-22.48,0-28.9-10.8-28.9-37.23S92.55,87.38,115,87.38s28.87,10.8,28.87,37.23S137.51,161.84,115,161.84Zm53.71,9.09a57.16,57.16,0,0,1-11.05-1.16l.69-4.9a75.79,75.79,0,0,0,9,.78c6,0,8.77-.93,8.77-6,0-3.34-1.5-5-4.4-5.42a59.44,59.44,0,0,0-7.52-.37h-.78v-4.67a65.43,65.43,0,0,0,8.3-.68c3-.42,4-2.29,4-5.08,0-4.48-3.12-4.86-7.44-4.86a74.23,74.23,0,0,0-9.6.78l-.63-4.8A55.4,55.4,0,0,1,169,133.44c7,0,13.32,1.45,13.32,9.23a8.82,8.82,0,0,1-5.32,8.54c3.53,1.36,5.9,3.74,5.9,8.22C182.85,168.26,177.49,170.93,168.74,170.93Z" />
	</svg>
)
