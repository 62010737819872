import React from 'react'
const ServiceGuarenteeIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 259.14 259.14"
		fill="currentColor"
		{...props}
	>
		<defs>
			<clipPath id="a">
				<path
					d="M103.46,56V81.51a.81.81,0,0,0,.81.81H153.8a.81.81,0,0,0,.81-.81V56a180.6,180.6,0,0,0-51.15,0"
					fill="none"
				/>
			</clipPath>
			<clipPath id="b">
				<path
					d="M103.16,53a.5.5,0,0,0-.46.55c.07.76.1,1.14.18,1.91a.5.5,0,0,0,.55.45,329.13,329.13,0,0,1,51.21,0,.5.5,0,0,0,.55-.45c.07-.77.11-1.15.18-1.91a.51.51,0,0,0-.46-.55c-8.68-.68-17.28-1-25.88-1s-17.2.33-25.87,1"
					fill="none"
				/>
			</clipPath>
			<clipPath id="c">
				<path
					d="M161.39,146.8a2.11,2.11,0,0,1,.77,1.87c2.6,0,2.46,1.86,2.46,1.86v2.12l1.4.34v1.52c3.61,1.08,4.52,1.85,4.52,1.85v1.17a4.51,4.51,0,0,0,2.69,0h0V156a1.41,1.41,0,0,0,.12-1.13,17,17,0,0,0-14.12-10.21,11.62,11.62,0,0,1-2.95-.42s4.12,1.54,5.11,2.53"
					fill="none"
				/>
			</clipPath>
			<clipPath id="d">
				<path
					d="M98.83,144.69A17,17,0,0,0,84.71,154.9a1.46,1.46,0,0,0,.12,1.13v1.5h0a4.48,4.48,0,0,0,2.68,0v-1.17s.92-.77,4.52-1.85V153l1.4-.34v-2.12s-.14-1.86,2.46-1.86a2.14,2.14,0,0,1,.77-1.87c1-1,5.12-2.53,5.12-2.53a11.67,11.67,0,0,1-3,.42"
					fill="none"
				/>
			</clipPath>
			<clipPath id="e">
				<path
					d="M110.89,193.85c0-3.38,8.14-6.12,18.18-6.12s18.18,2.74,18.18,6.12S139.12,200,129.07,200s-18.18-2.73-18.18-6.11m-.69-1.73h0a2.55,2.55,0,0,0,0,.78v1.89a2.56,2.56,0,0,0,0,.39c0,4.24,8.47,7.67,18.9,7.67S148,199.42,148,195.18v-3.06h0c-.5-4.05-8.76-7.28-18.88-7.28s-18.37,3.23-18.87,7.28"
					fill="none"
				/>
			</clipPath>
			<clipPath id="f">
				<path
					d="M96.67,146.8a2.14,2.14,0,0,0-.77,1.87H96l.11,0a2.06,2.06,0,0,1,1.76.9.75.75,0,0,1,.1.2l0,.06v44l32.26,11.24,29.89-11.24V149.66a2.49,2.49,0,0,1,1.8-1l.1,0h.11a2.11,2.11,0,0,0-.77-1.87,13.06,13.06,0,0,0-3.18-1.75c1.25.65,1.94,1.35,1.94,2.08a1.11,1.11,0,0,1,0,.17c-.15,1-1.51,1.88-3.8,2.69l-.86.28-.25.07c-5.52,1.65-15.17,2.75-26.15,2.75h0l-1.93,0h-.56l-1.51,0h-.44l-1.87-.07h-.38l-1.51-.07-.47,0c-1.23-.07-2.42-.15-3.58-.24l-.35,0-1.51-.14-.22,0c-.58,0-1.15-.12-1.71-.18h-.09l-1.53-.19-.21,0c-1.07-.15-2.1-.3-3.06-.48H108c-1.48-.26-2.82-.55-4-.86l0,0c-.8-.2-1.54-.42-2.19-.65-2.27-.8-3.63-1.72-3.77-2.68a1.11,1.11,0,0,1,0-.17c0-.82.85-1.6,2.4-2.3a15,15,0,0,0-3.73,2"
					fill="none"
				/>
			</clipPath>
			<clipPath id="g">
				<rect x="34.91" y="36.8" width="188.25" height="188.25" fill="none" />
			</clipPath>
			<clipPath id="i">
				<rect x="98.03" y="141.55" width="62.33" height="61.29" fill="none" />
			</clipPath>
			<clipPath id="k">
				<rect x="95.88" y="144.83" width="66.3" height="60.26" fill="none" />
			</clipPath>
			<clipPath id="l">
				<rect x="95.88" y="144.82" width="66.3" height="60.27" fill="none" />
			</clipPath>
			<clipPath id="m">
				<rect x="160.08" y="148.67" width="13.18" height="22.76" fill="none" />
			</clipPath>
			<clipPath id="n">
				<rect x="160.08" y="148.67" width="13.18" height="22.76" fill="none" />
			</clipPath>
			<clipPath id="p">
				<rect x="84.8" y="148.67" width="13.18" height="22.76" fill="none" />
			</clipPath>
			<clipPath id="t">
				<rect x="98.02" y="145.27" width="62.13" height="7.82" fill="none" />
			</clipPath>
			<clipPath id="w">
				<rect x="102.34" y="101.8" width="53.39" height="45.72" fill="none" />
			</clipPath>
			<clipPath id="x">
				<rect x="102.34" y="101.8" width="53.39" height="45.71" fill="none" />
			</clipPath>
			<clipPath id="y">
				<rect x="147.63" y="90.65" width="12.39" height="8.13" fill="none" />
			</clipPath>
			<clipPath id="z">
				<rect x="147.63" y="90.65" width="12.39" height="8.14" fill="none" />
			</clipPath>
			<clipPath id="aa">
				<rect x="102.34" y="90.65" width="48.43" height="1.27" fill="none" />
			</clipPath>
			<clipPath id="ab">
				<rect x="102.34" y="90.65" width="48.43" height="1.27" fill="none" />
			</clipPath>
			<clipPath id="ac">
				<rect x="160.08" y="123.96" width="1.87" height="1.1" fill="none" />
			</clipPath>
			<clipPath id="ad">
				<rect x="160.08" y="123.97" width="1.87" height="1.1" fill="none" />
			</clipPath>
			<clipPath id="ae">
				<rect x="120.85" y="128.14" width="1.51" height="0.07" fill="none" />
			</clipPath>
			<clipPath id="af">
				<rect x="120.85" y="128.14" width="1.51" height="0.07" fill="none" />
			</clipPath>
			<clipPath id="ag">
				<rect x="125.05" y="128.31" width="1.52" height="0.03" fill="none" />
			</clipPath>
			<clipPath id="ah">
				<rect x="125.05" y="128.31" width="1.52" height="0.03" fill="none" />
			</clipPath>
			<clipPath id="ai">
				<rect x="104.01" y="125.91" width="4.02" height="0.86" fill="none" />
			</clipPath>
			<clipPath id="ak">
				<rect x="127.12" y="128.35" width="1.93" height="0.01" fill="none" />
			</clipPath>
			<clipPath id="al">
				<rect x="127.12" y="128.34" width="1.93" height="0.01" fill="none" />
			</clipPath>
			<clipPath id="am">
				<rect x="122.74" y="128.23" width="1.86" height="0.06" fill="none" />
			</clipPath>
			<clipPath id="an">
				<rect x="122.74" y="128.23" width="1.87" height="0.06" fill="none" />
			</clipPath>
			<clipPath id="ao">
				<rect x="116.8" y="127.87" width="3.58" height="0.25" fill="none" />
			</clipPath>
			<clipPath id="ap">
				<rect x="116.8" y="127.87" width="3.58" height="0.25" fill="none" />
			</clipPath>
			<clipPath id="aq">
				<rect x="101.79" y="125.24" width="2.19" height="0.66" fill="none" />
			</clipPath>
			<clipPath id="ar">
				<rect x="101.79" y="125.25" width="2.19" height="0.66" fill="none" />
			</clipPath>
			<clipPath id="as">
				<rect x="108.12" y="126.79" width="3.06" height="0.47" fill="none" />
			</clipPath>
			<clipPath id="au">
				<rect x="111.39" y="127.3" width="1.54" height="0.19" fill="none" />
			</clipPath>
			<clipPath id="aw">
				<rect x="113.01" y="127.5" width="1.71" height="0.18" fill="none" />
			</clipPath>
			<clipPath id="ay">
				<rect x="114.94" y="127.7" width="1.51" height="0.14" fill="none" />
			</clipPath>
			<clipPath id="az">
				<rect x="114.94" y="127.7" width="1.51" height="0.14" fill="none" />
			</clipPath>
		</defs>
		<g>
			<path
				d="M129.57,241.13A111.56,111.56,0,1,0,18,129.57,111.56,111.56,0,0,0,129.57,241.13Zm0,18A129.57,129.57,0,1,1,259.14,129.57,129.57,129.57,0,0,1,129.57,259.14Z"
				fill="#fff"
			/>
			<path
				d="M129,54.14A173,173,0,0,0,103.46,56V81.51a.81.81,0,0,0,.81.81H153.8a.8.8,0,0,0,.8-.81V56A172.84,172.84,0,0,0,129,54.14"
				fill="#fff"
			/>
			<g clipPath="url(#a)">
				<rect x="154.32" y="53.49" width="0.29" height="30.13" fill="#a8aaad" />
				<rect x="153.02" y="53.49" width="0.3" height="30.13" fill="#a8aaad" />
				<rect x="151.62" y="53.49" width="0.41" height="30.13" fill="#a8aaad" />
				<rect x="150.03" y="53.49" width="0.59" height="30.13" fill="#a8aaad" />
				<rect x="148.44" y="53.49" width="0.56" height="30.13" fill="#a8aaad" />
				<rect x="146.74" y="53.49" width="0.63" height="30.13" fill="#a8aaad" />
				<rect x="145" y="53.44" width="0.74" height="30.13" fill="#a8aaad" />
				<rect x="143.07" y="53.44" width="0.7" height="30.13" fill="#a8aaad" />
				<rect x="140.86" y="53.44" width="0.9" height="30.13" fill="#a8aaad" />
				<rect x="138.27" y="53.44" width="1.08" height="30.13" fill="#a8aaad" />
				<rect x="135.22" y="53.44" width="1.36" height="30.13" fill="#a8aaad" />
				<rect x="103.46" y="53.49" width="0.29" height="30.13" fill="#a8aaad" />
				<rect x="104.75" y="53.49" width="0.3" height="30.13" fill="#a8aaad" />
				<rect x="106.04" y="53.49" width="0.41" height="30.13" fill="#a8aaad" />
				<rect x="107.45" y="53.49" width="0.59" height="30.13" fill="#a8aaad" />
				<rect x="109.08" y="53.49" width="0.56" height="30.13" fill="#a8aaad" />
				<rect x="110.7" y="53.49" width="0.63" height="30.13" fill="#a8aaad" />
				<rect x="112.33" y="53.44" width="0.74" height="30.13" fill="#a8aaad" />
				<rect x="114.31" y="53.44" width="0.7" height="30.13" fill="#a8aaad" />
				<rect x="116.31" y="53.44" width="0.9" height="30.13" fill="#a8aaad" />
				<rect x="118.72" y="53.44" width="1.08" height="30.13" fill="#a8aaad" />
				<rect x="121.48" y="53.44" width="1.36" height="30.13" fill="#a8aaad" />
				<rect x="130.8" y="53.44" width="2.04" height="30.13" fill="#a8aaad" />
				<rect x="125.23" y="53.44" width="2.04" height="30.13" fill="#a8aaad" />
			</g>
			<g clipPath="url(#b)">
				<path
					d="M154.91,55.44c-20.38-1.8-33.43-1.46-51.75,0a.51.51,0,0,0-.46.56c.07.76.11,1.14.18,1.9a.51.51,0,0,0,.55.46,326.68,326.68,0,0,1,51.21,0,.52.52,0,0,0,.55-.46c.07-.76.11-1.14.18-1.9a.51.51,0,0,0-.46-.56"
					fill="#a8aaad"
				/>
			</g>
			<g clipPath="url(#c)">
				<path
					d="M164.47,149.8a8.07,8.07,0,0,1,4.37,1.71c1.77,1.56,4.54,4.13,4.54,4.13l.83,2.66-6,.48L163.46,155Z"
					fill="#595a5c"
				/>
			</g>
			<g clipPath="url(#d)">
				<path
					d="M93.59,149.8a8,8,0,0,0-4.36,1.71c-1.77,1.56-4.55,4.13-4.55,4.13l-.83,2.66,6,.48,4.8-3.83Z"
					fill="#595a5c"
				/>
			</g>
			<g clipPath="url(#e)">
				<path
					d="M142.3,187l-12.88-5.32v-.28l-.34.14-.34-.14v.28L115.86,187c-5.33,2.16-5,6.82-5,6.82l17.84,1.8v.07l.34,0,.34,0v-.07l17.83-1.8s.37-4.66-4.95-6.82"
					fill="#bdbec0"
				/>
			</g>
			<g clipPath="url(#f)">
				<path
					d="M161.43,115.38H96.63a.34.34,0,0,1,0-.67h64.8a.34.34,0,0,1,0,.67"
					fill="#f2f3f2"
				/>
				<polygon
					points="155.73 134.63 102.34 126.46 102.34 115.38 155.73 115.38 155.73 134.63"
					fill="#e7e7e7"
				/>
				<path d="M160.25,135.45s.05.48-4.52,1V135l3.51.13.91.17Z" fill="#fff" />
				<path
					d="M102.34,118.63s-10.58,5.94,1.4,8.43c34.19,7.08,55.79,8.17,55.79,8.17s.58,0,.72.22v-.36s0-.11-.77-.22-36.81-4.81-57.14-10.44Z"
					fill="#f2f3f2"
				/>
				<path
					d="M160.25,145.25s.05.47-4.52.94v-1.44l3.51.14.91.17Z"
					fill="#fff"
				/>
				<path
					d="M102.34,128.43s-10.58,5.94,1.4,8.42c34.19,7.09,55.79,8.17,55.79,8.17s.58,0,.72.23v-.36s0-.11-.77-.23-36.81-4.8-57.14-10.43Z"
					fill="#f2f3f2"
				/>
				<path
					d="M160,120c-.65-2.25-4.22-4.63-4.22-4.63h-8.1c7.41,2.54,8.05,5.48,8.1,6.2v1.93c1-.16,4.86-1.25,4.22-3.5"
					fill="#fff"
				/>
			</g>
			<rect x="34.91" y="36.8" width="188.25" height="188.25" fill="none" />
			<g clipPath="url(#g)">
				<path
					d="M154.91,53a331.13,331.13,0,0,0-51.75,0,.5.5,0,0,0-.46.55c.07.76.11,1.14.18,1.91a.5.5,0,0,0,.55.45,329.13,329.13,0,0,1,51.21,0,.51.51,0,0,0,.55-.45c.07-.77.11-1.15.18-1.91a.5.5,0,0,0-.46-.55"
					fill="#fff"
				/>
				<path
					d="M129,52c8.6,0,17.2.33,25.88,1a.51.51,0,0,1,.46.55c-.07.76-.11,1.14-.18,1.91a.51.51,0,0,1-.51.45h0l-.59,0,.56.09V81.51a.81.81,0,0,1-.81.81H104.27a.81.81,0,0,1-.81-.81V56l.55-.09-.58,0h-.05a.49.49,0,0,1-.5-.45c-.08-.77-.11-1.15-.18-1.91a.5.5,0,0,1,.46-.55c8.67-.68,17.27-1,25.87-1m0-2.33c-8.6,0-17.36.35-26,1a2.84,2.84,0,0,0-2.6,3.09l0,.2.16,1.7a2.77,2.77,0,0,0,.58,1.47V81.51a3.13,3.13,0,0,0,3.13,3.13H153.8a3.13,3.13,0,0,0,3.13-3.13V57.14a2.85,2.85,0,0,0,.57-1.47l.06-.59.12-1.31a2.85,2.85,0,0,0-2.56-3.09c-8.73-.67-17.49-1-26.09-1"
					fill="#353e47"
				/>
			</g>
			<g clipPath="url(#g)">
				<g opacity="0.6">
					<g clipPath="url(#i)">
						<g clipPath="url(#i)">
							<path
								d="M160.36,146.89c0-2.95-14-5.34-31.16-5.34S98,143.94,98,146.89v48.4l30.18,7.55,32.06-7.55v-48a1,1,0,0,0,.09-.41"
								fill="#e7e7e7"
							/>
						</g>
					</g>
				</g>
				<g opacity="0.8">
					<g clipPath="url(#k)">
						<g clipPath="url(#l)">
							<path
								d="M161.39,146.8a12.75,12.75,0,0,0-3.18-1.74c1.25.64,1.94,1.34,1.94,2.07a1.11,1.11,0,0,1,0,.17c-.15,1-1.51,1.89-3.81,2.69l-.85.27-.25.08c-5.53,1.66-15.17,2.75-26.14,2.75h0l-1.93,0h-.55l-1.52,0h-.44l-1.87-.07h-.38l-1.51-.08-.47,0c-1.22-.07-2.42-.15-3.58-.25l-.35,0-1.51-.14-.22,0-1.7-.18h-.1l-1.53-.2-.21,0c-1.07-.15-2.1-.31-3.06-.48H108c-1.49-.26-2.83-.55-4-.86h0c-.8-.21-1.54-.43-2.19-.67h0c-2.27-.79-3.63-1.7-3.77-2.67a1.11,1.11,0,0,1,0-.17c0-.82.85-1.6,2.39-2.3a15.11,15.11,0,0,0-3.72,2,2.14,2.14,0,0,0-.77,1.87H96l.1,0a2.06,2.06,0,0,1,1.76.9.63.63,0,0,1,.1.2l0,.06v44l32.26,11.24,29.89-11.24V149.66a2.49,2.49,0,0,1,1.8-1l.1,0h.11a2.11,2.11,0,0,0-.77-1.87"
								fill="#fff"
							/>
						</g>
					</g>
				</g>
				<g opacity="0.8">
					<g clipPath="url(#m)">
						<g clipPath="url(#n)">
							<path
								d="M173.23,157.54a4.51,4.51,0,0,1-2.69,0v-1.17s-.91-.77-4.52-1.85V153l-1.4-.34v-2.12s.14-1.86-2.46-1.86H162l-.09,0h0a3.4,3.4,0,0,0-.43.08h0a2.42,2.42,0,0,0-.53.19h0a1.56,1.56,0,0,0-.87.82v21.63l3.84-3.39s.5,1.33,4.45,1.69,4.89-.64,4.89-1.69V157.53h0"
								fill="#eef0f2"
							/>
						</g>
					</g>
				</g>
				<path d="M160.08,149.94v0" fill="#e7e7e7" />
				<path
					d="M173.35,154.9a17,17,0,0,0-14.12-10.21,11.62,11.62,0,0,1-2.95-.42s4.13,1.54,5.11,2.53a2.11,2.11,0,0,1,.77,1.87c2.6,0,2.46,1.85,2.46,1.85v2.13l1.4.34v1.52c3.61,1.08,4.53,1.85,4.53,1.85v1.17a4.58,4.58,0,0,0,2.68,0h0V156a1.41,1.41,0,0,0,.12-1.13"
					fill="#818285"
				/>
			</g>
			<g clipPath="url(#g)">
				<path
					d="M164.28,149.47s-.56-.68-2,0S161,152,161,152v1.71l1.15.47v2.19l3.84-1.26V153l-1.4-.34v-2.12a1.63,1.63,0,0,0-.34-1.06"
					fill="#818285"
				/>
				<path
					d="M173.26,156.38c0,.21-.61.37-1.36.37s-1.36-.16-1.36-.37.61-.37,1.36-.37,1.36.16,1.36.37"
					fill="#818285"
				/>
				<g opacity="0.8">
					<g clipPath="url(#p)">
						<g clipPath="url(#p)">
							<path
								d="M84.84,157.54a4.48,4.48,0,0,0,2.68,0v-1.17s.92-.77,4.52-1.85V153l1.4-.34v-2.12s-.14-1.86,2.46-1.86H96l.09,0h0a3.4,3.4,0,0,1,.43.08h0a2.42,2.42,0,0,1,.53.19h0a1.51,1.51,0,0,1,.86.82.63.63,0,0,1,0,.14v21.49L94.14,168s-.5,1.33-4.44,1.69-4.9-.64-4.9-1.69V157.53h0"
								fill="#eef0f2"
							/>
						</g>
					</g>
				</g>
				<path d="M98,149.94V150a.07.07,0,0,0,0-.05" fill="#e7e7e7" />
				<path d="M95.92,148.67h0" fill="#e7e7e7" />
				<path d="M95.92,148.67h0" fill="#e7e7e7" />
				<path
					d="M84.71,154.9a17,17,0,0,1,14.12-10.21,11.67,11.67,0,0,0,3-.42s-4.13,1.54-5.12,2.53a2.14,2.14,0,0,0-.77,1.87c-2.6,0-2.46,1.85-2.46,1.85v2.13l-1.39.34v1.52c-3.61,1.08-4.53,1.85-4.53,1.85v1.17a4.57,4.57,0,0,1-2.68,0h0V156a1.46,1.46,0,0,1-.12-1.13"
					fill="#818285"
				/>
			</g>
			<g clipPath="url(#g)">
				<path
					d="M93.78,149.47s.57-.68,2,0S97,152,97,152v1.71l-1.16.47v2.19l-3.83-1.26V153l1.39-.34v-2.12a1.63,1.63,0,0,1,.34-1.06"
					fill="#818285"
				/>
				<path
					d="M84.8,156.38c0,.21.61.37,1.36.37s1.36-.16,1.36-.37-.61-.37-1.36-.37-1.36.16-1.36.37"
					fill="#818285"
				/>
				<path
					d="M129.07,180.12c-17.16,0-31.07,6.14-31.07,13.73s13.91,13.73,31.07,13.73,31.08-6.15,31.08-13.73-13.91-13.73-31.08-13.73M148,195.18c0,4.24-8.47,7.67-18.91,7.67s-18.9-3.43-18.9-7.67a2.56,2.56,0,0,1,0-.39V192.9a2.55,2.55,0,0,1,0-.78c.5-4.05,8.76-7.28,18.87-7.28s18.38,3.23,18.88,7.28h0Z"
					fill="#939598"
				/>
				<path
					d="M129.07,184.84c-10.11,0-18.37,3.23-18.87,7.28a2.55,2.55,0,0,0,0,.78v1.89a2.56,2.56,0,0,0,0,.39c0,4.24,8.46,7.67,18.9,7.67S148,199.42,148,195.18v-3.06h0c-.5-4.05-8.76-7.28-18.88-7.28m0,15.12c-10,0-18.17-2.74-18.17-6.11s8.14-6.12,18.17-6.12,18.18,2.74,18.18,6.12S139.11,200,129.07,200"
					fill="#e7e7e7"
				/>
				<path
					d="M129.07,184.84c-10.11,0-18.37,3.23-18.87,7.28a2.55,2.55,0,0,0,0,.78v1.89a2.56,2.56,0,0,0,0,.39c0,4.24,8.46,7.67,18.9,7.67S148,199.42,148,195.18v-3.06h0c-.5-4.05-8.76-7.28-18.88-7.28m0,15.12c-10,0-18.17-2.74-18.17-6.11s8.14-6.12,18.17-6.12,18.18,2.74,18.18,6.12S139.11,200,129.07,200"
					fill="#e7e7e7"
				/>
			</g>
			<g clipPath="url(#g)">
				<path
					d="M129.07,180.11c-17.16,0-31.07,6.15-31.07,13.74s13.91,13.73,31.07,13.73,31.08-6.15,31.08-13.73-13.91-13.74-31.08-13.74m0,24.28c-15.07,0-27.3-4.9-27.3-10.95s12.23-10.95,27.3-10.95,27.31,4.9,27.31,10.95-12.23,10.95-27.31,10.95"
					fill="#e7e7e7"
				/>
				<g opacity="0.5">
					<g clipPath="url(#t)">
						<g clipPath="url(#t)">
							<path
								d="M159.17,145.69l-.38-.27a2.21,2.21,0,0,1,.25.3,1.15,1.15,0,0,1,.22.64c0,2.35-8.56,4.35-20.43,5-2.28.13-4.68.22-7.17.25l-2.15,0c-16.43,0-29.75-2.38-29.75-5.32a1.31,1.31,0,0,1,.38-.84c-.17-.08-.34-.16-.49-.25-1,.59-1.63,1.23-1.63,1.89,0,3.27,13.91,5.93,31.07,5.93,3.6,0,7.06-.12,10.28-.34,1.8-.12,3.51-.27,5.14-.45,9.34-1,15.64-2.94,15.64-5.14a2,2,0,0,0-1-1.47"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
			</g>
			<g clipPath="url(#g)">
				<g opacity="0.6">
					<g clipPath="url(#w)">
						<g clipPath="url(#x)">
							<path
								d="M102.34,142.23s3.9,5.28,26.69,5.28c22.08,0,26.7-5.28,26.7-5.28V109.9l-53.39-8.1Z"
								fill="#fff"
							/>
						</g>
					</g>
				</g>
				<path
					d="M161.43,90.65H96.63a.34.34,0,0,1,0-.67h64.8a.34.34,0,0,1,0,.67"
					fill="#f2f3f2"
				/>
				<polygon
					points="155.73 109.9 102.34 101.73 102.34 90.65 155.73 90.65 155.73 109.9"
					fill="#fff"
				/>
				<path
					d="M160.25,110.72s.05.48-4.52,1v-1.44l3.51.13.91.17Z"
					fill="#fff"
				/>
				<path
					d="M102.34,93.9s-10.58,5.94,1.4,8.43c34.19,7.08,55.79,8.17,55.79,8.17s.58,0,.72.22v-.36s0-.11-.77-.22-36.81-4.81-57.14-10.44Z"
					fill="#f2f3f2"
				/>
				<path
					d="M160.25,120.52s.05.47-4.52.94V120l3.51.14.91.17Z"
					fill="#fff"
				/>
				<path
					d="M102.34,103.7s-10.58,5.94,1.4,8.42c34.19,7.09,55.79,8.17,55.79,8.17s.58,0,.72.23v-.36s0-.11-.77-.23-36.81-4.8-57.14-10.43Z"
					fill="#f2f3f2"
				/>
				<path
					d="M160.25,130.32s.05.47-4.52.94v-1.44l3.51.14.91.17Z"
					fill="#fff"
				/>
				<path
					d="M160.25,140.11s.05.48-4.52.95v-1.44l3.51.14.91.16Z"
					fill="#fff"
				/>
				<path
					d="M102.34,123.3s-10.58,5.94,1.4,8.42c34.19,7.09,55.79,8.17,55.79,8.17s.58,0,.72.22v-.36s0-.11-.77-.22-36.81-4.81-57.14-10.44Z"
					fill="#f2f3f2"
				/>
				<path
					d="M102.34,138.55v-5.8s-10.58,5.94,1.4,8.43a140.47,140.47,0,0,0,23.8,3.22s-25.2-4-25.2-5.85"
					fill="#f2f3f2"
				/>
				<path
					d="M160,95.28c-.65-2.25-4.22-4.63-4.22-4.63h-8.1c7.41,2.54,8.05,5.48,8.1,6.2v1.93c1-.16,4.86-1.25,4.22-3.5"
					fill="#fff"
				/>
				<g opacity="0.1">
					<g clipPath="url(#y)">
						<g clipPath="url(#z)">
							<path
								d="M160,95.28c-.65-2.25-4.22-4.63-4.22-4.63h-8.1c7.41,2.54,8.05,5.48,8.1,6.2v1.93c1-.16,4.86-1.25,4.22-3.5"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
				<g opacity="0.5">
					<g clipPath="url(#aa)">
						<g clipPath="url(#ab)">
							<path
								d="M147.59,90.65H102.34v1.27h48.43l-1.37-.6c-.5-.23-1.81-.67-1.81-.67"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
				<g opacity="0.46">
					<g clipPath="url(#ac)">
						<g clipPath="url(#ad)">
							<path
								d="M160.08,125.07h0c.27-.79,1.4-1,1.87-1.1-.47.06-1.6.31-1.87,1.1"
								fill="#e7e7e7"
							/>
						</g>
					</g>
				</g>
				<path d="M162.14,123.94h0" fill="#e7e7e7" />
				<path d="M162.14,123.94h0" fill="#e7e7e7" />
				<path d="M122.36,128.22l-1.51-.08,1.51.08" fill="#e7e7e7" />
				<g opacity="0.47">
					<g clipPath="url(#ae)">
						<g clipPath="url(#af)">
							<path d="M122.36,128.22l-1.51-.08,1.51.08" fill="#939598" />
						</g>
					</g>
				</g>
				<path d="M126.57,128.34l-1.52,0,1.52,0" fill="#e7e7e7" />
				<g opacity="0.47">
					<g clipPath="url(#ag)">
						<g clipPath="url(#ah)">
							<path d="M126.57,128.34l-1.52,0,1.52,0" fill="#939598" />
						</g>
					</g>
				</g>
				<path
					d="M108,126.78c-1.48-.26-2.83-.55-4-.86,1.19.31,2.53.6,4,.86"
					fill="#e7e7e7"
				/>
				<g opacity="0.47">
					<g clipPath="url(#ai)">
						<g clipPath="url(#ai)">
							<path
								d="M108,126.78c-1.48-.26-2.83-.55-4-.86,1.19.31,2.53.6,4,.86"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
				<path d="M129.05,128.36l-1.93,0,1.93,0" fill="#e7e7e7" />
				<g opacity="0.47">
					<g clipPath="url(#ak)">
						<g clipPath="url(#al)">
							<path d="M129.05,128.36l-1.93,0,1.93,0" fill="#939598" />
						</g>
					</g>
				</g>
				<path d="M124.61,128.3l-1.87-.07,1.87.07" fill="#e7e7e7" />
				<g opacity="0.47">
					<g clipPath="url(#am)">
						<g clipPath="url(#an)">
							<path d="M124.61,128.3l-1.87-.07,1.87.07" fill="#939598" />
						</g>
					</g>
				</g>
				<path
					d="M120.38,128.12c-1.22-.07-2.42-.15-3.58-.25,1.16.1,2.36.18,3.58.25"
					fill="#e7e7e7"
				/>
				<g opacity="0.47">
					<g clipPath="url(#ao)">
						<g clipPath="url(#ap)">
							<path
								d="M120.38,128.12c-1.22-.07-2.42-.15-3.58-.25,1.16.1,2.36.18,3.58.25"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
				<path
					d="M104,125.91c-.8-.21-1.54-.43-2.19-.66.65.23,1.39.45,2.19.66"
					fill="#e7e7e7"
				/>
				<g opacity="0.47">
					<g clipPath="url(#aq)">
						<g clipPath="url(#ar)">
							<path
								d="M104,125.91c-.8-.21-1.54-.43-2.19-.66.65.23,1.39.45,2.19.66"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
				<path
					d="M111.18,127.27c-1.07-.15-2.1-.31-3.06-.48,1,.17,2,.33,3.06.48"
					fill="#e7e7e7"
				/>
				<g opacity="0.47">
					<g clipPath="url(#as)">
						<g clipPath="url(#as)">
							<path
								d="M111.18,127.27c-1.07-.15-2.1-.31-3.06-.48,1,.17,2,.33,3.06.48"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
				<path d="M112.92,127.49l-1.53-.19,1.53.19" fill="#e7e7e7" />
				<g opacity="0.47">
					<g clipPath="url(#au)">
						<g clipPath="url(#au)">
							<path d="M112.92,127.49l-1.53-.19,1.53.19" fill="#939598" />
						</g>
					</g>
				</g>
				<path
					d="M114.72,127.68c-.58-.05-1.15-.12-1.71-.18.56.06,1.13.13,1.71.18"
					fill="#e7e7e7"
				/>
				<g opacity="0.47">
					<g clipPath="url(#aw)">
						<g clipPath="url(#aw)">
							<path
								d="M114.72,127.68c-.58-.05-1.15-.12-1.71-.18.56.06,1.13.13,1.71.18"
								fill="#939598"
							/>
						</g>
					</g>
				</g>
				<path d="M116.45,127.84l-1.51-.13,1.51.13" fill="#e7e7e7" />
				<g opacity="0.47">
					<g clipPath="url(#ay)">
						<g clipPath="url(#az)">
							<path d="M116.45,127.84l-1.51-.13,1.51.13" fill="#939598" />
						</g>
					</g>
				</g>
				<path
					d="M102.34,119.29V113.5s-7.47,4.19-2.74,7a8.27,8.27,0,0,1,.79-.41l2-.81"
					fill="#f2f3f2"
				/>
				<path
					d="M159.48,129.73c-.78-.11-36.81-4.81-57.14-10.44v2.29c.43.12.89.23,1.4.34,34.19,7.09,55.79,8.17,55.79,8.17s.59,0,.73.23V130s0-.12-.78-.23"
					fill="#f2f3f2"
				/>
				<path
					d="M100.4,120.1a7.42,7.42,0,0,0-.8.41,11.23,11.23,0,0,0,2.74,1.07v-2.29l-1.94.81"
					fill="#f2f3f2"
				/>
				<path
					d="M102.34,103.7s-10.58,5.94,1.4,8.42c34.19,7.09,55.79,8.17,55.79,8.17s.58,0,.72.23v-.36s0-.11-.77-.23-36.81-4.8-57.14-10.43Z"
					fill="#f2f3f2"
				/>
				<path
					d="M102.34,119.29V113.5s-7.53,4.23-2.69,7a15,15,0,0,1,2.9-1.19l-.21-.06"
					fill="#f2f3f2"
				/>
				<path
					d="M112.08,105.59,104,103.37,100,102.3c-.72-.21-1.37-.35-2.19-.63L95.62,101a66.58,66.58,0,0,1-9.32-3.76A21.64,21.64,0,0,1,84,95.85a7.05,7.05,0,0,1-2-1.95,3.43,3.43,0,0,1-.34-.75c0-.15,0-.31-.07-.46v-.17s0-.26,0-.15v0l0-.15a2.22,2.22,0,0,1,.07-.31,4.78,4.78,0,0,1,.23-.45,2.32,2.32,0,0,1,.55-.63,6.87,6.87,0,0,1,2.35-1.19,25.69,25.69,0,0,1,4.54-.94c1.45-.19,2.82-.29,4.08-.38,2.53-.16,4.64-.18,6.11-.21h2.31l-2.3.16c-1.47.13-3.57.29-6.06.62-1.25.18-2.6.37-4,.66a25.33,25.33,0,0,0-4.28,1.19,6,6,0,0,0-1.85,1.07c-.09.11-.24.25-.23.3a.3.3,0,0,1-.08.08c0,.07,0,.19,0,.29l0,.15v0c0,.12,0-.14,0-.11v0l0,0,0,.16c.07.11.11.23.2.35A5.71,5.71,0,0,0,85,94.44a20.6,20.6,0,0,0,2.15,1.09,65.68,65.68,0,0,0,9.23,3l2.24.56c.63.17,1.44.3,2.12.45l4,.8,8.25,1.65Z"
					fill="#353e47"
				/>
				<polygon
					points="110.89 108.36 112.87 98.88 116.62 104.61 110.89 108.36"
					fill="#353e47"
				/>
				<path
					d="M112.08,134.83l-7.31-2-3.55-1-1.93-.54-1.05-.3-1-.32c-2.73-.89-5.63-1.91-8.46-3.15a33.57,33.57,0,0,1-4.12-2.15,10.8,10.8,0,0,1-1.88-1.46,4.71,4.71,0,0,1-.77-1,2.2,2.2,0,0,1-.24-1.42,2.24,2.24,0,0,1,.26-.67,2.61,2.61,0,0,1,.43-.53,4.27,4.27,0,0,1,1-.66,10.09,10.09,0,0,1,2-.75,26.34,26.34,0,0,1,3.65-.72c2.28-.3,4.18-.41,5.51-.47l2.08-.08-2.07.24c-1.32.16-3.2.41-5.42.87a28,28,0,0,0-3.49,1,9,9,0,0,0-1.72.8c-.55.34-1,.76-1,1.18s.28.88.78,1.3a9.25,9.25,0,0,0,1.71,1.14,32.65,32.65,0,0,0,4,1.73c2.82,1,5.72,1.77,8.46,2.43l1,.25,1,.2,2,.38,3.61.72,7.45,1.46Z"
					fill="#353e47"
				/>
				<polygon
					points="110.89 137.61 112.87 128.14 116.62 133.86 110.89 137.61"
					fill="#353e47"
				/>
				<path
					d="M156.18,135.33l1.54.45,4.07,1.23c1.68.52,3.63,1.16,5.7,1.89,1,.36,2.11.75,3.19,1.16s2.15.84,3.25,1.3a57.06,57.06,0,0,1,6.23,3,23.13,23.13,0,0,1,2.68,1.79,6.21,6.21,0,0,1,1.92,2.27,2.06,2.06,0,0,1,.1,1.4,2.27,2.27,0,0,1-.71,1,5.71,5.71,0,0,1-1.64.91,9.6,9.6,0,0,1-1.53.46,10.43,10.43,0,0,0,1.46-.62,5.4,5.4,0,0,0,1.46-1,1.9,1.9,0,0,0,.49-.86,1.51,1.51,0,0,0-.2-1,5.43,5.43,0,0,0-1.89-1.78,22.22,22.22,0,0,0-2.71-1.42,56,56,0,0,0-6.33-2.25c-1.08-.33-2.23-.65-3.31-.93l-3.23-.8c-2.1-.49-4.07-.91-5.77-1.25l-4.1-.77-1.57-.28Z"
					fill="#353e47"
				/>
				<path
					d="M156.18,106.15l2.09.57c1.33.38,3.23.92,5.49,1.62s4.89,1.56,7.69,2.6a68,68,0,0,1,8.56,3.77,18.06,18.06,0,0,1,4,2.81,4,4,0,0,1,1.33,2.42l0,.2v0c0,.06,0-.09,0,.14v0l0,.07-.05.28a3.51,3.51,0,0,1-.34.76c-.15.2-.31.36-.47.54s-.35.26-.52.39a12.29,12.29,0,0,1-4.18,1.45,36,36,0,0,1-3.8.51c-2.35.2-4.31.25-5.69.27h-2.15l2.15-.18c1.36-.13,3.31-.33,5.61-.72a36.39,36.39,0,0,0,3.67-.79,11.61,11.61,0,0,0,3.66-1.58l.34-.3.24-.32c0-.11.07-.08.1-.26l0-.28v-.1c0,.21,0,0,0,.1h0l0-.09a2.72,2.72,0,0,0-1-1.38,16.12,16.12,0,0,0-3.81-2.21c-1.39-.62-2.83-1.16-4.26-1.65s-2.85-.91-4.28-1.32c-2.77-.78-5.42-1.42-7.69-1.93s-4.17-.9-5.51-1.16l-2.1-.4Z"
					fill="#353e47"
				/>
				<path d="M162,148.81h.1l-.1,0" fill="#353e47" />
				<path d="M159.49,145.76h0l0,0h0" fill="#353e47" />
				<path
					d="M175.69,154.21a19.52,19.52,0,0,0-14.76-11.63,7,7,0,0,0-1.1-.25l-1.87-.25a9,9,0,0,1-2.35,2.1l.52.14,3.35.46a2,2,0,0,1,.74.18A16.93,16.93,0,0,1,173.35,155a1.23,1.23,0,0,1,0,.73h0l-.12,2.74v9.66c0,1.06-.94,2.06-4.89,1.7s-4.45-1.7-4.45-1.7l-3.66,3.23v24l-.3.07c-1.71,6.87-14.88,12.21-30.89,12.21s-29.21-5.36-30.89-12.24l-.15,0v-.86a5.68,5.68,0,0,1,0-.59l0-22.42-3.84-3.39s-.5,1.34-4.44,1.7-4.89-.64-4.89-1.7v-9.66l-.13-2.74h0a1.31,1.31,0,0,1,0-.73A17,17,0,0,1,98.84,144.8a11.62,11.62,0,0,0,3-.42l-1.4.56-.56.28a19,19,0,0,1,3.36-1.15,9.56,9.56,0,0,1-1.82-2l-.31.09,0-.08a8.71,8.71,0,0,1-2.21.3h-.14l-.13,0a19.55,19.55,0,0,0-16.17,11.86c-.14.42,0,13.94,0,13.94,0,1,.43,4.24,5.86,4.24.53,0,1.11,0,1.73-.09a12.31,12.31,0,0,0,4.1-1l1.49,1.33,0,21.3c0,.24,0,.47,0,.71v2.67l.72.18c3.23,7.41,16.56,12.64,32.79,12.64s29.5-5.21,32.77-12.59l.89-.21V172.49l1.32-1.16a12.18,12.18,0,0,0,4.09,1c.62.06,1.21.09,1.74.09a7.36,7.36,0,0,0,4-.94,3.88,3.88,0,0,0,1.81-3.3v-7.38l.16-5.28a3.76,3.76,0,0,0-.2-1.28"
					fill="#353e47"
				/>
				<path d="M155.73,129.79Z" fill="#353e47" />
				<path d="M155.73,120Z" fill="#353e47" />
				<path d="M155.73,139.59Z" fill="#353e47" />
				<path
					d="M164.24,90.28a2.81,2.81,0,0,0-2.81-2.8H96.63a2.8,2.8,0,0,0,0,5.6h2.44c-1.77,1.38-3.79,3.54-3.47,6.14a5.27,5.27,0,0,0,3.09,4C97,104.56,95.3,106.6,95.6,109a5.27,5.27,0,0,0,3.09,4c-1.53,1.27-3.12,3.1-3.13,5.27a5,5,0,0,0,2.85,4.39l.26.15c-1.65,1.38-3.37,3.42-3.07,5.83a5.16,5.16,0,0,0,2.85,3.82c-1.59,1.37-3.13,3.33-2.85,5.63.24,2,1.67,3.46,4.27,4.51V143l.48.65c.78,1.05,5.81,6.29,28.68,6.29,22.55,0,28-5.5,28.56-6.13l.52-.6a15.68,15.68,0,0,0,2.34-.49,2.76,2.76,0,0,0,2.27-2.65v-.36c0-.84-.37-2.31-2.9-2.67l-1.62-.22v-3.42a14.84,14.84,0,0,0,2.25-.48,2.76,2.76,0,0,0,2.27-2.65v-.36c0-.84-.37-2.31-2.88-2.66L158.2,127v-3.42a13.7,13.7,0,0,0,2.25-.48,2.76,2.76,0,0,0,2.27-2.64v-.37c0-.84-.37-2.3-2.9-2.67l-1.62-.21v-3.42a16.19,16.19,0,0,0,2.25-.48,2.78,2.78,0,0,0,2.27-2.65v-.37c0-.84-.37-2.3-2.9-2.66l-1.62-.22v-6.82A7.2,7.2,0,0,0,161.83,98a4.14,4.14,0,0,0,.49-3.41,6.17,6.17,0,0,0-.66-1.49,2.82,2.82,0,0,0,2.58-2.8m-2.81.34h-5.7S159.3,93,160,95.25s-3.21,3.33-4.22,3.5v10.84l3.75.51c.78.11.78.22.78.22v.37s0,.47-4.53.94v7.76l3.75.51c.78.11.78.22.78.22v.36s0,.48-4.53,1v7.76l3.75.51c.78.11.78.22.78.22v.36s0,.48-4.53.95V139l3.75.52c.78.11.78.22.78.22v.36s0,.48-4.53.94v1.18s-4.62,5.28-26.7,5.28c-22.79,0-26.69-5.28-26.69-5.28v-1.4c-9.68-2.64,0-8.08,0-8.08v-1.37c-9.68-2.65,0-8.09,0-8.09v-1.71a11.48,11.48,0,0,1-2.68-1h0c-4.84-2.82,2.69-7.05,2.69-7.05v-1.71c-9.68-2.65,0-8.08,0-8.08V102c-9.68-2.64,0-8.08,0-8.08V90.62H96.63a.34.34,0,0,1,0-.67h64.8a.34.34,0,0,1,0,.67"
					fill="#353e47"
				/>
				<path d="M155.73,110.19Z" fill="#353e47" />
			</g>
		</g>
	</svg>
)
export default ServiceGuarenteeIcon
