import React from 'react'
const EliteBuilderPROSeries = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 514.94 141.73"
		fill="currentColor"
		{...props}
	>
		<path
			d="M13.32,46.59A10.07,10.07,0,0,1,14.69,41c.91-1.4,2.65-2.1,5.2-2.1H64.72q7.57,0,10.77,3.88t3.2,11V87.3H73.94V53.87a19.72,19.72,0,0,0-.41-4.1,8.93,8.93,0,0,0-1.46-3.37,7.12,7.12,0,0,0-2.83-2.28,10.81,10.81,0,0,0-4.52-.82H48.38v44H43.63v-44H21a3.64,3.64,0,0,0-2.15.55c-.52.36-.77,1.33-.77,2.91V87.3H13.32Z"
			fill="#59c8de"
		/>
		<path
			d="M88.54,30.52V23.4h5.12v7.12Zm.19,8.4h4.74V87.3H88.73Z"
			fill="#59c8de"
		/>
		<path
			d="M103.51,46.59A10.07,10.07,0,0,1,104.88,41c.92-1.4,2.65-2.1,5.21-2.1h17.8q7.58,0,10.77,3.88t3.2,11V87.3h-4.75V53.87a19.72,19.72,0,0,0-.41-4.1,8.78,8.78,0,0,0-1.46-3.37,7.05,7.05,0,0,0-2.83-2.28,10.78,10.78,0,0,0-4.52-.82H111.18a3.61,3.61,0,0,0-2.14.55q-.78.54-.78,2.91V87.3h-4.75Z"
			fill="#59c8de"
		/>
		<path
			d="M166.78,87.3a17,17,0,0,1-8.26-1.78,13.06,13.06,0,0,1-5.11-5A22,22,0,0,1,150.8,73a58.44,58.44,0,0,1-.73-9.54,54.45,54.45,0,0,1,1-10.82,19.63,19.63,0,0,1,3.29-7.85,14.55,14.55,0,0,1,6.35-4.8,27,27,0,0,1,10.13-1.64,34.2,34.2,0,0,1,8.31,1,17.47,17.47,0,0,1,6.62,3.16,14.73,14.73,0,0,1,4.33,5.73,21.36,21.36,0,0,1,1.55,8.66q0,4.21-1.41,6.09t-5.53,1.87H155a49.17,49.17,0,0,0,.42,6.38,19.36,19.36,0,0,0,1.64,5.83,9.86,9.86,0,0,0,9.72,5.87h23.74V87.3Zm17.89-26.84a1.67,1.67,0,0,0,1.6-.72,2.89,2.89,0,0,0,.41-1.46v-.63a21.47,21.47,0,0,0-1.05-7.31,10.35,10.35,0,0,0-3.1-4.55,12.13,12.13,0,0,0-5-2.36,29.67,29.67,0,0,0-6.66-.68,26.77,26.77,0,0,0-7,.82,10.57,10.57,0,0,0-5,2.86A12.74,12.74,0,0,0,156,51.88a32.23,32.23,0,0,0-1,8.58Z"
			fill="#59c8de"
		/>
		<path
			d="M199.83,55.53q0-8.3,3.92-12.46T216,38.92h3.29V43.3H216q-6.21,0-8.81,3.28t-2.6,10.11V87.3h-4.75Z"
			fill="#59c8de"
		/>
		<path
			d="M247.85,38.92q6,0,8.81,3.19c1.86,2.13,2.78,5.12,2.78,9V80.91a7.43,7.43,0,0,1-1.32,4.8q-1.32,1.59-5.7,1.59H233.79q-7.22,0-10.13-3.51T220.74,72.7q0-7.58,3-11t10.09-3.38H254.7V50.29a8.17,8.17,0,0,0-1.51-4.86q-1.5-2.13-5.34-2.13H224.39V38.92ZM232.51,62.75a7.33,7.33,0,0,0-3.51.72,5.38,5.38,0,0,0-2.1,2.08,8.57,8.57,0,0,0-1,3.17,32.34,32.34,0,0,0-.22,4,33.37,33.37,0,0,0,.22,4,9,9,0,0,0,1,3.26,5.55,5.55,0,0,0,2.1,2.17,6.87,6.87,0,0,0,3.51.77h19.91a2.07,2.07,0,0,0,1.78-.68,2.47,2.47,0,0,0,.5-1.49v-18Z"
			fill="#59c8de"
		/>
		<path d="M269.3,23.4h4.75V87.3H269.3Z" fill="#59c8de" />
		<path
			d="M303.59,89.12a15.33,15.33,0,0,0,3.53-4.65,20.8,20.8,0,0,0,1-16.23,33.89,33.89,0,0,0-3.93-7.79c-1.79-2.79-3.72-5.43-5.57-8.15a82.59,82.59,0,0,1-6-10c-2.7-5.49-3.09-10.94-.73-16.32a20.38,20.38,0,0,1,3.91-5.77c.06-.06.11-.15.26-.1a30.81,30.81,0,0,0-1.49,2.7,15.72,15.72,0,0,0-.88,11.53A26.08,26.08,0,0,0,296.8,41a52,52,0,0,0,3.59,5c2.52,3.14,5.22,6,7.87,9a84.09,84.09,0,0,1,5.95,7.23,30.3,30.3,0,0,1,4,7,14.24,14.24,0,0,1,.15,10.37,14,14,0,0,1-8.12,8.57,15.72,15.72,0,0,1-6.29,1.05A3,3,0,0,1,303.59,89.12Z"
			fill="#59c8de"
		/>
		<path
			d="M352.2,80.11c.12.3.26.61.41.94s.34.49.58.49.51-.19.63-.58a6,6,0,0,1,.27-.76l10-38.46c.47-1.67,1.16-2.74,2.06-3.19a4.72,4.72,0,0,1,4.85,0c.9.45,1.59,1.52,2.07,3.19L383,80.2a6,6,0,0,1,.27.76c.12.39.33.58.63.58s.43-.16.59-.49.28-.64.4-.94L395.6,38.42h4.67l-11.68,44.2a8.15,8.15,0,0,1-1.39,2.74A3.8,3.8,0,0,1,384,86.57a4.71,4.71,0,0,1-2.15-.44,4.53,4.53,0,0,1-1.44-1.08,5.74,5.74,0,0,1-.86-1.3,4.79,4.79,0,0,1-.4-1.22l-9.7-37.19a10.64,10.64,0,0,0-.41-1.17c-.15-.36-.31-.54-.49-.54s-.35.18-.5.54a10.66,10.66,0,0,0-.4,1.17L358,82.53c-.13.36-.27.77-.45,1.22a4.47,4.47,0,0,1-4.41,2.82,3.81,3.81,0,0,1-3.19-1.21,8.15,8.15,0,0,1-1.39-2.74l-11.68-44.2h4.67Z"
			fill="#5889a8"
		/>
		<path d="M405.12,23.83h5v7h-5Zm.19,14.59H410V86h-4.67Z" fill="#5889a8" />
		<path
			d="M419.86,46a9.9,9.9,0,0,1,1.35-5.48q1.34-2.05,5.12-2.06h44.11q7.46,0,10.6,3.82t3.15,10.82V86h-4.67V53.13a19.11,19.11,0,0,0-.41-4,8.74,8.74,0,0,0-1.44-3.32,6.81,6.81,0,0,0-2.78-2.24,10.63,10.63,0,0,0-4.45-.81H454.36V86h-4.67V42.73H427.4a3.62,3.62,0,0,0-2.11.54c-.51.36-.76,1.31-.76,2.87V86h-4.67Z"
			fill="#5889a8"
		/>
		<path
			d="M317,8.31c-.57.62-1.12,1.12-1.6,1.73a19.36,19.36,0,0,0-3.87,14,23.25,23.25,0,0,0,2.86,9A84,84,0,0,0,320,41.79a101.31,101.31,0,0,1,7.68,12.33A19.64,19.64,0,0,1,330,64.32a17.65,17.65,0,0,1-2.67,8.31,18.16,18.16,0,0,1-2.75,3.5c-.1-.26.05-.34.12-.45a16.52,16.52,0,0,0,2.73-9.28A17.45,17.45,0,0,0,325.93,60a36.14,36.14,0,0,0-5.11-8.31c-2.69-3.46-5.62-6.59-8.49-9.81a76.91,76.91,0,0,1-6.69-8.31,26,26,0,0,1-3.3-6.47,13.83,13.83,0,0,1,.3-9.81,13.8,13.8,0,0,1,8.14-8.15,16,16,0,0,1,5.86-.88Z"
			fill="#5889a8"
		/>
		<path
			d="M488.72,38.43v1.46h2v5.32h1.85V39.89h2V38.43Zm12.31,0-2,3.87-2-3.87h-1.43v6.78h1.61V41.66L498.4,44h1.15l1.27-2.43v3.6h1.58V38.43Z"
			fill="#5889a8"
		/>
		<path d="M15.67,132.11a4.2,4.2,0,0,1-2.77-.8,3.64,3.64,0,0,1-.93-2.87v-20a3.58,3.58,0,0,1,.93-2.85,4.25,4.25,0,0,1,2.77-.78h16v1.87H15.75c-.75,0-1.22.15-1.4.43a2.56,2.56,0,0,0-.28,1.33v8.77H31.31v1.87H14.07v9.36a2.67,2.67,0,0,0,.28,1.35c.18.3.65.45,1.4.45H31.86v1.87Z" />
		<path d="M39,104.81h2v27.3H39Z" />
		<path d="M48.78,107.85v-3H51v3Zm.08,3.59h2v20.67h-2Z" />
		<path d="M65.44,132.11a7.67,7.67,0,0,1-3.26-.56,3.79,3.79,0,0,1-1.68-1.48,4.76,4.76,0,0,1-.6-2.08c0-.78-.08-1.59-.08-2.42V113.31H56.58v-1.87h3.24v-6.63h2v6.63h5.54v1.87H61.85v12.81a10,10,0,0,0,.17,2,2.53,2.53,0,0,0,.65,1.26,2.43,2.43,0,0,0,1.25.66,8.27,8.27,0,0,0,2,.2h1.49v1.87Z" />
		<path d="M79.05,132.11a7.24,7.24,0,0,1-3.53-.76,5.67,5.67,0,0,1-2.19-2.13A9.51,9.51,0,0,1,72.22,126a24.52,24.52,0,0,1-.31-4.08,23.54,23.54,0,0,1,.41-4.62,8.31,8.31,0,0,1,1.4-3.35,6.26,6.26,0,0,1,2.71-2,11.51,11.51,0,0,1,4.33-.7,14.23,14.23,0,0,1,3.55.41A7.3,7.3,0,0,1,87.14,113,6.24,6.24,0,0,1,89,115.41a9.23,9.23,0,0,1,.67,3.7,4.38,4.38,0,0,1-.61,2.6,2.8,2.8,0,0,1-2.36.81H74a20.8,20.8,0,0,0,.17,2.72,8.47,8.47,0,0,0,.7,2.49,4.22,4.22,0,0,0,4.16,2.51H89.19v1.87Zm7.64-11.47a.7.7,0,0,0,.68-.31,1.2,1.2,0,0,0,.18-.62v-.27a9.12,9.12,0,0,0-.45-3.12,4.35,4.35,0,0,0-1.33-1.94,5,5,0,0,0-2.12-1,12.82,12.82,0,0,0-2.85-.29,11.24,11.24,0,0,0-3,.35,4.52,4.52,0,0,0-2.13,1.22A5.35,5.35,0,0,0,74.43,117a13.48,13.48,0,0,0-.41,3.66Z" />
		<path d="M127.84,118.38a6.38,6.38,0,0,1,1.79.59,3.22,3.22,0,0,1,1.21,1.11,5.54,5.54,0,0,1,.7,1.85,14,14,0,0,1,.23,2.81,15.83,15.83,0,0,1-.23,2.81,6.67,6.67,0,0,1-.86,2.34,4.69,4.69,0,0,1-1.69,1.62,5.63,5.63,0,0,1-2.75.6h-12.6a8.38,8.38,0,0,1-1.46-.12,2.5,2.5,0,0,1-1.19-.5,2.55,2.55,0,0,1-.8-1.08,4.57,4.57,0,0,1-.29-1.81V108.4a4.34,4.34,0,0,1,.72-2.54,2.77,2.77,0,0,1,2.47-1h12.6a6.46,6.46,0,0,1,2.71.51,4.36,4.36,0,0,1,1.79,1.46,6.37,6.37,0,0,1,1,2.32,15.29,15.29,0,0,1,.15,5.27,5.9,5.9,0,0,1-.56,1.81,3.76,3.76,0,0,1-2.94,2ZM112,117.45h12.79a6.55,6.55,0,0,0,2.28-.33,2.92,2.92,0,0,0,1.37-1,3.91,3.91,0,0,0,.64-1.72,15.89,15.89,0,0,0,.16-2.47,12.33,12.33,0,0,0-.16-2.09,4.59,4.59,0,0,0-.58-1.66,3.07,3.07,0,0,0-1.17-1.09,4.23,4.23,0,0,0-2-.39H113.56a1.78,1.78,0,0,0-1.17.33,1.57,1.57,0,0,0-.39,1.23Zm0,10.69a3.88,3.88,0,0,0,.24,1.59q.23.51,1.44.51h11.66a5.61,5.61,0,0,0,2.07-.33,2.8,2.8,0,0,0,1.28-1,4.36,4.36,0,0,0,.65-1.69,15.22,15.22,0,0,0,.17-2.41,19.65,19.65,0,0,0-.11-2.08,5,5,0,0,0-.53-1.75,2.94,2.94,0,0,0-1.17-1.2,4.11,4.11,0,0,0-2.09-.45H112Z" />
		<path d="M156.11,128.83a4.28,4.28,0,0,1-.58,2.38,2.47,2.47,0,0,1-2.23.9h-7.76q-3.24,0-4.6-1.66a7.19,7.19,0,0,1-1.36-4.7V111.44h2v14.28a8.2,8.2,0,0,0,.18,1.76,3.89,3.89,0,0,0,.62,1.44,3.08,3.08,0,0,0,1.21,1,4.69,4.69,0,0,0,1.93.35h7.3a1.53,1.53,0,0,0,.91-.24c.22-.15.33-.57.33-1.24V111.44h2Z" />
		<path d="M163.83,107.85v-3H166v3Zm.08,3.59h2v20.67h-2Z" />
		<path d="M173.74,104.81h2v27.3h-2Z" />
		<path d="M191.52,132.11a12.77,12.77,0,0,1-4.33-.62,5.93,5.93,0,0,1-2.67-1.91,7.49,7.49,0,0,1-1.36-3.22,24.44,24.44,0,0,1-.37-4.58,23.06,23.06,0,0,1,.37-4.41,7.73,7.73,0,0,1,1.36-3.24,6.19,6.19,0,0,1,2.67-2,11.73,11.73,0,0,1,4.33-.68h6.24v-6.63h2v24.3a6.51,6.51,0,0,1-.1,1.21,2.18,2.18,0,0,1-.39,1,1.84,1.84,0,0,1-.84.63,4.23,4.23,0,0,1-1.48.21Zm0-18.8a9.18,9.18,0,0,0-3.35.51,4.19,4.19,0,0,0-2,1.55,6.71,6.71,0,0,0-1,2.64,22.87,22.87,0,0,0-.27,3.77,24.41,24.41,0,0,0,.27,3.94,6.44,6.44,0,0,0,1,2.62,4,4,0,0,0,2,1.45,10.3,10.3,0,0,0,3.35.45h4.68c.7,0,1.14-.13,1.31-.39a2.63,2.63,0,0,0,.25-1.36V113.31Z" />
		<path d="M214,132.11a7.24,7.24,0,0,1-3.53-.76,5.67,5.67,0,0,1-2.19-2.13,9.51,9.51,0,0,1-1.11-3.21,24.52,24.52,0,0,1-.31-4.08,23.54,23.54,0,0,1,.41-4.62,8.31,8.31,0,0,1,1.4-3.35,6.26,6.26,0,0,1,2.71-2,11.51,11.51,0,0,1,4.33-.7,14.23,14.23,0,0,1,3.55.41A7.3,7.3,0,0,1,222,113a6.24,6.24,0,0,1,1.85,2.44,9.24,9.24,0,0,1,.66,3.7,4.39,4.39,0,0,1-.6,2.6,2.8,2.8,0,0,1-2.36.81H208.92a20.8,20.8,0,0,0,.17,2.72,8.47,8.47,0,0,0,.7,2.49,4.22,4.22,0,0,0,4.16,2.51h10.14v1.87Zm7.64-11.47a.7.7,0,0,0,.68-.31,1.2,1.2,0,0,0,.18-.62v-.27a9.12,9.12,0,0,0-.45-3.12,4.35,4.35,0,0,0-1.33-1.94,5,5,0,0,0-2.12-1,12.82,12.82,0,0,0-2.85-.29,11.24,11.24,0,0,0-3,.35,4.52,4.52,0,0,0-2.13,1.22,5.35,5.35,0,0,0-1.24,2.33,13.48,13.48,0,0,0-.41,3.66Z" />
		<path d="M231.57,118.54a7.51,7.51,0,0,1,1.68-5.33q1.68-1.77,5.23-1.77h1.4v1.87h-1.4a4.56,4.56,0,0,0-3.77,1.4A6.94,6.94,0,0,0,233.6,119v13.08h-2Z" />
		<path d="M258.72,132.11V108.05a3.25,3.25,0,0,1,.8-2.56,3.91,3.91,0,0,1,2.55-.68h11.47a10.09,10.09,0,0,1,3.29.47A5.09,5.09,0,0,1,279,106.7a5.77,5.77,0,0,1,1.21,2.48,15.85,15.85,0,0,1,.37,3.63,17.2,17.2,0,0,1-.37,3.84,6,6,0,0,1-1.21,2.51,4.62,4.62,0,0,1-2.19,1.39,11.48,11.48,0,0,1-3.29.41H260.82v11.15Zm14.82-13a6.71,6.71,0,0,0,2.51-.39,3,3,0,0,0,1.47-1.19,5,5,0,0,0,.66-2,21.86,21.86,0,0,0,.15-2.73,17,17,0,0,0-.17-2.6,4.76,4.76,0,0,0-.7-1.93,3.35,3.35,0,0,0-1.47-1.19,6.22,6.22,0,0,0-2.45-.41H262.38c-.78,0-1.23.16-1.36.49a3.22,3.22,0,0,0-.2,1.19v10.72Z" />
		<path d="M288.4,109a4.47,4.47,0,0,1,1-3.12,4.08,4.08,0,0,1,3.12-1H304a7.05,7.05,0,0,1,3.1.59,5,5,0,0,1,2,1.65,6.73,6.73,0,0,1,1,2.54,16.06,16.06,0,0,1,.29,3.22,18.87,18.87,0,0,1-.27,3.33,7,7,0,0,1-1,2.57,4.62,4.62,0,0,1-1.85,1.68,6.56,6.56,0,0,1-3,.61h-6.05v.19l12.8,10.92H308l-12.05-10.37-.52-.49a.8.8,0,0,1-.22-.61V120c0-.6.32-.9.94-.9h8.07a4.21,4.21,0,0,0,2.21-.5,3.05,3.05,0,0,0,1.17-1.37,6.46,6.46,0,0,0,.46-2c.07-.76.1-1.58.1-2.43a19.19,19.19,0,0,0-.14-2.5,5,5,0,0,0-.6-1.93,3.24,3.24,0,0,0-1.36-1.25,5.47,5.47,0,0,0-2.42-.45H292.45a2.48,2.48,0,0,0-1.3.39,1.55,1.55,0,0,0-.65,1.45v23.59h-2.1Z" />
		<path d="M331.34,104.5a21.08,21.08,0,0,1,6.24.8,9,9,0,0,1,4.07,2.49,9.88,9.88,0,0,1,2.2,4.33,30.33,30.33,0,0,1,0,12.68,9.88,9.88,0,0,1-2.2,4.33,9,9,0,0,1-4.07,2.49,24.74,24.74,0,0,1-12.48,0,9.17,9.17,0,0,1-4.1-2.49,9.93,9.93,0,0,1-2.24-4.33,29.9,29.9,0,0,1,0-12.68,9.93,9.93,0,0,1,2.24-4.33,9.17,9.17,0,0,1,4.1-2.49A21.08,21.08,0,0,1,331.34,104.5Zm-11,14a27.59,27.59,0,0,0,.47,5.56,8.23,8.23,0,0,0,1.69,3.74,6.92,6.92,0,0,0,3.36,2.13,22.75,22.75,0,0,0,10.88,0,6.94,6.94,0,0,0,3.35-2.13,8.24,8.24,0,0,0,1.7-3.74,33.12,33.12,0,0,0,0-11.12,8.24,8.24,0,0,0-1.7-3.74,7,7,0,0,0-3.35-2.13,22.75,22.75,0,0,0-10.88,0,7,7,0,0,0-3.36,2.13,8.23,8.23,0,0,0-1.69,3.74A27.59,27.59,0,0,0,320.34,118.46Z" />
		<path d="M364.56,130.24H380a3.67,3.67,0,0,0,1.19-.2,2.37,2.37,0,0,0,1-.72,3.86,3.86,0,0,0,.72-1.42,8,8,0,0,0,.28-2.34,7.17,7.17,0,0,0-.12-1.31,3.39,3.39,0,0,0-.47-1.21,4.12,4.12,0,0,0-1-1.07,6.7,6.7,0,0,0-1.82-.9l-10.88-3.74a12.35,12.35,0,0,1-2-.9,5.4,5.4,0,0,1-1.48-1.23,5.13,5.13,0,0,1-.93-1.75,7.91,7.91,0,0,1-.34-2.48,6.51,6.51,0,0,1,1.47-4.54q1.45-1.62,4.7-1.62h13.84v1.87H370.3a4,4,0,0,0-3,1,5,5,0,0,0-.87,3.31c0,.45,0,.87.06,1.29a3.11,3.11,0,0,0,.33,1.19,3,3,0,0,0,.82,1,4.7,4.7,0,0,0,1.56.82l10.6,3.74c.81.29,1.56.59,2.27.9a6.17,6.17,0,0,1,1.79,1.17,5,5,0,0,1,1.17,1.78,7.11,7.11,0,0,1,.43,2.67,11.69,11.69,0,0,1-.26,2.44,5.66,5.66,0,0,1-.89,2.1,4.65,4.65,0,0,1-1.7,1.46,5.54,5.54,0,0,1-2.61.55H364.56Z" />
		<path d="M399,132.11a7.24,7.24,0,0,1-3.53-.76,5.65,5.65,0,0,1-2.18-2.13,9.3,9.3,0,0,1-1.12-3.21,25.64,25.64,0,0,1-.31-4.08,23.54,23.54,0,0,1,.41-4.62,8.32,8.32,0,0,1,1.41-3.35,6.14,6.14,0,0,1,2.71-2,11.44,11.44,0,0,1,4.33-.7,14.21,14.21,0,0,1,3.54.41,7.23,7.23,0,0,1,2.83,1.35,6.26,6.26,0,0,1,1.86,2.44,9.24,9.24,0,0,1,.66,3.7,4.31,4.31,0,0,1-.61,2.6,2.8,2.8,0,0,1-2.36.81H394a22.7,22.7,0,0,0,.17,2.72,8.48,8.48,0,0,0,.71,2.49,4.4,4.4,0,0,0,1.52,1.81,4.45,4.45,0,0,0,2.63.7h10.14v1.87Zm7.64-11.47a.72.72,0,0,0,.69-.31,1.29,1.29,0,0,0,.17-.62v-.27a9.12,9.12,0,0,0-.45-3.12,4.41,4.41,0,0,0-1.32-1.94,5.14,5.14,0,0,0-2.13-1,12.79,12.79,0,0,0-2.84-.29,11.26,11.26,0,0,0-3,.35,4.48,4.48,0,0,0-2.12,1.22,5.36,5.36,0,0,0-1.25,2.33,13.89,13.89,0,0,0-.41,3.66Z" />
		<path d="M416.67,118.54a7.55,7.55,0,0,1,1.67-5.33q1.68-1.77,5.23-1.77H425v1.87h-1.4a4.53,4.53,0,0,0-3.76,1.4A6.94,6.94,0,0,0,418.7,119v13.08h-2Z" />
		<path d="M430.59,107.85v-3h2.18v3Zm.08,3.59h2v20.67h-2Z" />
		<path d="M446.93,132.11a7.24,7.24,0,0,1-3.53-.76,5.65,5.65,0,0,1-2.18-2.13,9.09,9.09,0,0,1-1.11-3.21,24.48,24.48,0,0,1-.32-4.08,23.54,23.54,0,0,1,.41-4.62,8.32,8.32,0,0,1,1.41-3.35,6.14,6.14,0,0,1,2.71-2,11.44,11.44,0,0,1,4.33-.7,14.35,14.35,0,0,1,3.55.41A7.25,7.25,0,0,1,455,113a6.26,6.26,0,0,1,1.86,2.44,9.24,9.24,0,0,1,.66,3.7,4.31,4.31,0,0,1-.61,2.6,2.79,2.79,0,0,1-2.36.81H441.9a20.82,20.82,0,0,0,.18,2.72,8.17,8.17,0,0,0,.7,2.49,4.4,4.4,0,0,0,1.52,1.81,4.45,4.45,0,0,0,2.63.7h10.14v1.87Zm7.64-11.47a.72.72,0,0,0,.69-.31,1.29,1.29,0,0,0,.17-.62v-.27a9.12,9.12,0,0,0-.45-3.12,4.41,4.41,0,0,0-1.32-1.94,5.14,5.14,0,0,0-2.13-1,12.79,12.79,0,0,0-2.84-.29,11.26,11.26,0,0,0-3,.35,4.48,4.48,0,0,0-2.12,1.22,5.36,5.36,0,0,0-1.25,2.33,13.89,13.89,0,0,0-.41,3.66Z" />
		<path d="M463.78,130.24h11.85c1.23,0,2-.3,2.4-.89a4.53,4.53,0,0,0,.57-2.45,3,3,0,0,0-.31-1.49,2.49,2.49,0,0,0-1-1,6.87,6.87,0,0,0-1.75-.68c-.73-.2-1.62-.41-2.65-.64l-3.59-.82a15.71,15.71,0,0,1-2.36-.68,6.2,6.2,0,0,1-1.85-1.07,4.41,4.41,0,0,1-1.21-1.66,6.2,6.2,0,0,1-.43-2.48,4.7,4.7,0,0,1,1.42-3.72,5.42,5.42,0,0,1,3.76-1.27h10.73v1.87H468.65a3.08,3.08,0,0,0-2.41.76,3.51,3.51,0,0,0-.67,2.38,4.5,4.5,0,0,0,.28,1.73,2.61,2.61,0,0,0,.82,1.11,4.45,4.45,0,0,0,1.38.69c.56.19,1.21.37,2,.55l3.74.86c1.17.26,2.19.51,3,.76a7.63,7.63,0,0,1,2.17.95,3.79,3.79,0,0,1,1.31,1.48,5.56,5.56,0,0,1,.43,2.34,9,9,0,0,1-.24,2.17,3.85,3.85,0,0,1-.82,1.64,3.62,3.62,0,0,1-1.56,1,7.46,7.46,0,0,1-2.46.35H463.78Z" />
	</svg>
)
export default EliteBuilderPROSeries
